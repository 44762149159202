import React from "react";
import HealthInsuranceIcon from "../../../../icons/nonMotor.svg";
import Whatsapp from "../../../../icons/whatsapp.svg";
import Facebook from "../../../../icons/facebook.svg";
import LinkedIn from "../../../../icons/linkedin.svg";
import Twitter from "../../../../icons/twitter.svg";
import Modal from "../QIS_Modal";

function NonMotorInsuranceModal() {
  return (
    <Modal openButtonText="Get Quote">
      <form
        className="p-6 relative z-50"
        method="POST"
        action="https://api.sheetmonkey.io/form/4eKZdckj24R34ifZrqvqLv"
      >
        <div className="flex-col">
          <div className="m-auto sm:w-[22rem] flex">
            <img src={HealthInsuranceIcon} alt="" />
            <h2 className="ml-2 text-2xl font-bold">Non Motor Registration</h2>
          </div>
          <div className="mt-3 m-auto sm:w-[50rem] h-05 bg-retechgen-blue"></div>
        </div>

        <div className=" mt-8 m-auto xs:w-[12rem] sm:w-[50rem]">
          <div className="flex flex-wrap justify-around xs:w-[12rem] sm:w-[50rem]">
            <div className="flex-col w-80">
              <label
                htmlFor="insured_name"
                className="block text-sm pb-1 ml-2 text-black/70 font-medium"
              >
                Insured Name*
              </label>
              <input
                className="w-72 h-11 p-2 mb-6 text-black/70 font-medium border-black/50 border-2 rounded-xl "
                type="text"
                id="insured_name"
                name="insured_name"
              />
            </div>

            <div className="flex-col w-80">
              <label
                htmlFor="address"
                className="block text-sm pb-1 ml-2 text-black/70 font-medium"
              >
                Address*
              </label>
              <input
                className="w-72 h-11 p-2 mb-6 text-black/70 font-medium border-black/50 border-2 rounded-xl "
                type="text"
                id="address"
                name="address"
              />
            </div>

            <div className="flex-col w-80">
              <label
                htmlFor="phone_number"
                className="block text-sm pb-1 ml-2 text-black/70 font-medium"
              >
                Phone Number*
              </label>
              <input
                className="w-72 h-11 p-2 mb-6 text-black/70 font-medium border-black/50 border-2 rounded-xl "
                type="number"
                id="phone_number"
                name="phone_number"
              />
            </div>

            <div className="flex-col w-80">
              <label
                htmlFor="email"
                className="block text-sm pb-1 ml-2 text-black/70 font-medium"
              >
                Email*
              </label>
              <input
                className="w-72 h-11 p-2 mb-6 text-black/70 font-medium border-black/50 border-2 rounded-xl "
                type="email"
                id="email"
                name="email"
              />
            </div>

            <div className="flex-col xs:ml-10 sm:ml-0 w-80">
              <label
                htmlFor="rc_copy"
                className="block text-sm pb-1 ml-2 text-black/70 font-medium"
              >
                PYP/RC copy(Enter Google Drive Link)*
              </label>
              <input
                className="w-72 h-11 p-2 mb-6 text-black/70 font-medium border-black/50 border-2 rounded-xl "
                type="text"
                id="PYP/RC copy"
                name="PYP/RC copy"
              />
            </div>

            <div className="flex-col xs:mt-2 xs:ml-4 sm:ml-0 sm:mt-0 w-80">
              <button className=" self-center hover:bg-retechgen-blue flex h-12 w-36 bg-theme-blue text-white rounded-2xl text-sm shadow-button_shadow mt-1">
                <p className=" self-center m-auto font-semibold">Get Quote</p>
              </button>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <h2 className="text-center font-medium text-lg text-black">
            Contact Us Directly!
          </h2>

          <div className="mt-4 mb-2 m-auto w-40">
          <ul className="flex justify-around ">
            <li className=" border border-solid border-[#00000014] p-1 rounded-[50%] cursor-pointer">
              <a href="https://api.whatsapp.com/send?phone=919307675497"><img src={Whatsapp} alt="Whatsapp" /></a>
              
            </li>
            <li className=" border border-solid border-[#00000014] p-1 rounded-[50%] cursor-pointer">
              <a href="https://www.facebook.com/retechgen" target="_blank"><img  className="px-1 h-6" src={Facebook} alt="Facebook" /></a>
              
            </li>
            <li className=" border border-solid border-[#00000014] p-1 rounded-[50%] cursor-pointer">
              <a href="https://www.linkedin.com/company/retechgen/"><img className="h-[1.2rem]" src={LinkedIn} alt="LinkedIn" /></a>
            </li>
            <li className=" border border-solid border-[#00000014] p-1 rounded-[50%] cursor-pointer">
              <a href="https://twitter.com/retechgen"><img className="h-6" src={Twitter} alt="Twitter" /></a>
            </li>
          </ul>
          </div>

          <div className="mt-3 m-auto sm:w-[50rem] h-05 bg-black/70"></div>
        </div>
      </form>
    </Modal>
  );
}

export default NonMotorInsuranceModal;
