import React from "react";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import "./App.css";
import "./tailwindCSS/Tailwind_Output.css";
import routeConfig from "./routes";

const App = () => (
  <>
  <Router>
    <Routes>
    {routeConfig.routes.map((route) => (
      <Route
        key={route.name}
        path={route.path}
        element={route.component}
      />
    ))}
    </Routes>
  </Router>
  </>
);


export default App;
