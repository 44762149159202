import React from "react";
import Navbar from "../../shared/Header/Navbar";
import Footer from "../../shared/Footer/Footer";

import Joinus_bg from "../../icons/Join_us_bg.png";
import Joinus_culture from "../../icons/joinus_culture.svg";
import Joinus_growth from "../../icons/joinus_growth.svg";
import Joinus_innovation from "../../icons/joinus_innovation.svg";
import Touch_left from "../../icons/touch_left.svg";
import Touch_right from "../../icons/touch_right.svg";
import Joinus_job_right from "../../icons/joinus job_right.svg";
import UploadIcon from "../../icons/uploadIcon.svg";
import { useEffect } from "react";

function JoinusPage() {
  const [file, setFile] = React.useState(null);
  function clickUpload() {
    document.getElementById("resume").click();
  }
  function handleFileChange() {
    setFile(document.getElementById("resume").files[0]);
  }
  useEffect(() => {
    if (file) {
      document.getElementById("uploadButton").style.display = "none";
      document.getElementById("fileName").style.display = "block";
      document.getElementById("fileName").innerHTML = file.name;
    }
  }, [file]);
  return (
    <div>
      <Navbar />
      {window.scrollTo(0, 0)}

      <div className="relative bg-[#EBE9E6]/20">
        <img
          className="object-cover h-142 w-screen"
          src={Joinus_bg}
          alt="About Us"
        />
        <div className="  absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <h1 className="text-5xl font-bold text-white text-center pb-4 xs:pt-28 sm:pt-0">
            Join Us
          </h1>
          <p className="text-white text-base text-center mt-5">
            Our dedicated and qualified professionals are continuously exploring
            &amp; innovating to avail our elite clients with best in class,
            reliable and prompt IT consulting, software development, design
            services, custom products, mobility and cloud operations with
            end-to-end customization.
          </p>

          <button className="mt-7 hover:bg-[#0f778e] flex h-11 w-48 mx-auto border-white border-solid border-2  text-white rounded-2xl text-sm ">
            <p className=" self-center m-auto font-medium">Current Opening</p>
          </button>
        </div>
      </div>

      {/* Objectives & Goals Section */}
      <section className=" pb-32 bg-[#EBE9E6]/20 relative">
        <img
          className="absolute left-0 -bottom-18 h-80"
          src={Touch_left}
          alt=""
        />
        <img className="absolute right-0 top-8 h-80" src={Touch_right} alt="" />

        <div className="flex flex-col pt-16 ">
          <h2 className="mt-4 m-auto text-4xl font-bold text-theme-deep-blue inline-block">
            WHY <p className="text-theme-text-blue inline-block">JOIN</p> US?
          </h2>
          <div className="m-auto w-64 mt-4 h-1 bg-theme-deep-blue"></div>
        </div>

        <div className="flex xs:mt-2 sm:mt-18 justify-around xs:px-6 sm:px-28 flex-wrap">
          <div className="flex mt-14 relative flex-col w-80 h-94 pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <h2 className="text-theme-deep-blue font-semibold text-2xl mx-auto mt-10">
              Exponential Growth
            </h2>
            <img className="h-22 mt-8" src={Joinus_growth} alt="" />
            <p className="p-6 text-center text-black/70 font-medium text-sm leading-6">
              Work with one of the best environments and peers to get
              exponential growth in your career!
            </p>

            {/* <div className='cursor-pointer hover:bg-[#1B8DA6] absolute -bottom-8 left-32 flex w-16 h-16  m-auto rounded-full shadow-card_circle_shadow bg-white'>
                                <span className='hover:text-white text-retechgen-blue text-2xl self-center mx-auto p-6'>&gt;</span>
                        </div> */}
          </div>

          <div className="flex mt-14 relative flex-col w-80 h-94 pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <h2 className="text-theme-deep-blue font-semibold text-2xl mx-auto mt-10">
              Innovation is your call
            </h2>
            <img className="h-22 mt-8" src={Joinus_innovation} alt="" />
            <p className="p-6 text-center text-black/70 font-medium text-sm leading-6">
              With usage of latest innovative technologies, you get better
              insight of technologies used in real life products!
            </p>

            {/* <div className='cursor-pointer hover:bg-[#1B8DA6] absolute -bottom-8 left-32 flex w-16 h-16  m-auto rounded-full shadow-card_circle_shadow bg-white'>
                                <span className='hover:text-white text-2xl text-retechgen-blue self-center mx-auto p-6'>&gt;</span>
                        </div> */}
          </div>

          <div className="flex mt-14 relative flex-col w-80 h-94 pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <h2 className="text-theme-deep-blue font-semibold text-2xl mx-auto mt-10">
              Work Culture
            </h2>
            <img className="h-22 mt-8" src={Joinus_culture} alt="" />
            <p className="p-6 text-center text-black/70 font-medium text-sm leading-6">
              Employees are continuously encouraged to work as a team, have each
              other's back, and bring the best outcomes in every project.
            </p>
            {/* 
                        <div className='cursor-pointer hover:bg-[#1B8DA6] absolute -bottom-8 left-32 flex w-16 h-16  m-auto rounded-full shadow-card_circle_shadow bg-white'>
                                <span className='hover:text-white text-2xl text-retechgen-blue self-center mx-auto p-6'>&gt;</span>
                        </div> */}
          </div>
        </div>
      </section>

      {/* Job Application */}
      <section className=" sm:pb-26 xs:pb-70 bg-[#1B8DA6]/10 relative">
        <img
          className="absolute right-0 top-12 h-80"
          src={Joinus_job_right}
          alt=""
        />

        <div className="flex flex-col pt-16 ">
          <h2 className="mt-4 m-auto text-4xl font-bold text-theme-deep-blue inline-block xs:pl-6 sm:pl-0">
            we look forward to{" "}
            <p className="text-theme-text-blue inline-block xs:pl-2 sm:pl-0">
              connect
            </p>{" "}
            with you
          </h2>

          <h2 className="mt-6 m-auto text-2xl font-bold text-theme-deep-blue inline-block">
            Job Application
          </h2>
          <div className="m-auto w-48 mt-3 h-1 bg-theme-deep-blue"></div>
        </div>

        <div className="sm:flex-col xs:flex-row flex-wrap mx-auto my-16 sm:w-190 xs:w-88 h-128 relative ">
          <form
            className="m-auto xs:-ml-4 sm:ml-0 sm:w-190 xs:w-88 flex column-2 flex-wrap"
            method="POST"
            action="https://api.sheetmonkey.io/form/aSCR7NBQGF9GtjCL3s5qTb"
          >
            <input
              className="m-4 p-3 h-12 sm:w-96 xs:w-88 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 outline-1 outline-[#1b8da694] rounded-md"
              type="text"
              placeholder="First Name *"
              name="f_name"
              id="f_name"
              required
            />

            <input
              className="m-4 p-3 h-12 sm:w-96 xs:w-88 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 outline-1 outline-[#1b8da694] rounded-md"
              type="text"
              placeholder="Last Name *"
              name="l_name"
              id="l_name"
              required
            />

            <input
              className="m-4 p-3 h-12 sm:w-96 xs:w-88 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 outline-1 outline-[#1b8da694] rounded-md"
              type="number"
              placeholder="Mobile Number *"
              name="number"
              id="number"
              required
            />

            <input
              className="m-4 p-3 h-12 sm:w-96 xs:w-88 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 outline-1 outline-[#1b8da694] rounded-md"
              type="email"
              placeholder="Email *"
              name="email"
              id="email"
              required
            />

            <input
              className="m-4 p-3 h-12 sm:w-96 xs:w-88 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 outline-1 outline-[#1b8da694] rounded-md"
              type="text"
              placeholder="Level of Education *"
              name="edu_level"
              id="edu_level"
              required
            />

            <select
              className="m-4 p-3 h-12 sm:w-96 xs:w-88 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 outline-1 outline-[#1b8da694] rounded-md"
              name="job_role"
              id="job_role"
              required
              defaultValue={"default"}
            >
              <option value="frontend">Frontend Developer</option>
              <option value="full_stack">Full Stack Developer</option>
              <option value="backend">Backend Developer</option>
              <option value="ui_ux">UI/UX</option>
              <option value="default" disabled>
                Job Role *
              </option>
            </select>

            <textarea
              className="mt-5 m-4 p-3 h-36 sm:w-190 xs:w-88 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 outline-1 outline-[#1b8da694] rounded-md"
              name="message"
              id="message"
              placeholder="Enter message here..."
            ></textarea>

            <input
              className="m-4 p-3 h-12 sm:w-96 xs:w-88 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 outline-1 outline-[#1b8da694] rounded-md"
              type="text"
              placeholder="Resume(Google Drive Link) *"
              name="resume"
              id="resume"
              required
            />
            <br />
            <button className="mt-2 ml-4 hover:bg-retechgen-blue flex h-12 w-36 bg-theme-blue text-white text-sm shadow-button_shadow rounded-md">
              <p className=" self-center m-auto font-medium">Apply Now</p>
            </button>
          </form>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default JoinusPage;
