import React from "react";
import Navbar from "../../shared/Header/Navbar";
import Maps from "../ContactPage/Maps";

import ConnectUs from "../../icons/Connect_us.svg";
import Contact_email from "../../icons/contact_email.svg";
import Contact_location from "../../icons/contact_location.svg";
import Contact_telephone from "../../icons/contact_telephone.svg";
import Contact_Clip from "../../icons/Contact_form_clip.svg";
import Footer from "../../shared/Footer/Footer";
import Fade from "react-reveal/Fade";

function ContactPage() {
  return (
    <>
      <Navbar />
      {window.scrollTo(0, 0)}

      {/* Landing Page */}
      <div className=" flex relative sm:h-[42rem] xs:h-[36rem]">
      <Fade bottom>
        <div className="flex  absolute xs:top-[14rem] sm:top-[18rem] flex-col self-center justify-around w-full z-20">
          <h2 className="m-auto text-5xl font-bold text-white inline-block text-center">
            Contact Us
          </h2>

          <p className="m-auto text-center mt-8 text-white xs:w-[22rem] sm:w-[40rem] xs:p-4 sm-p-0">
            Got a project in mind? We’d love to hear about it. Take five minutes
            to fill out our project form so that we can get to know you and
            understand your project.
          </p>
        </div>
        </Fade>

        <img
          className="w-screen absolute z-0 h-[inherit] object-cover"
          src={ConnectUs}
          alt=""
        />
      </div>

      {/* Reach out to us */}
      <section className="relative xl:h-150 xs:h-[80rem] mt-5">
        <div className="absolute left-28 top-10 bg-[#D9D9D9]/20 h-12 w-12 rounded-md"></div>
        <div className="absolute left-36 top-26 bg-[#D9D9D9]/20 h-8 w-8 rounded-md"></div>

        <div className="flex justify-evenly flex-wrap">
        <Fade left>
          <div className="sm:m-10 xs:m-6 sm:mx-30 xs:mx-10 flex-col self-center">
            <h2 className=" text-theme-purple/60 font-semibold mb-2">
              Get In Touch
            </h2>
            <h2 className="self-center text-left font-bold text-[#1F2347] sm:text-4xl xs:text-3xl leading-10 pb-2">
              We’re Here to&nbsp;
              <p className=" text-left font-bold text-retechgen-blue/90 sm:text-4xl xs:text-3xl leading-10 inline-block">
                Help
              </p>
              &nbsp; You
            </h2>

            <div className="flex mt-5">
              <div className="flex w-12 h-12 bg-white rounded-full shadow-button_shadow m-auto self-center mr-6">
                <img
                  className="self-center m-auto"
                  src={Contact_telephone}
                  alt=""
                />
              </div>

              <div className="mt-5">
                <h2 className="text-black/40 font-semibold text-base">
                  Call Anytime
                </h2>
                <h2 className="text-black font-semibold text-lg mt-1">
                    9027875514
                </h2>
                <div className="mt-4 sm:w-94 xs:w-70 bg-black/20 h-02"></div>
              </div>
            </div>

            <div className="flex mt-5">
              <div className="flex w-12 h-12 bg-white rounded-full shadow-button_shadow m-auto self-center mr-6">
                <img
                  className="self-center m-auto"
                  src={Contact_email}
                  alt=""
                />
              </div>

              <div className="mt-5">
                <h2 className="text-black/40 font-semibold text-base">
                  Send Email
                </h2>
                <h2 className="text-black font-semibold text-lg mt-1">
                    ajit.painuly92@gmail.com
                </h2>
                <div className="mt-4 sm:w-94 xs:w-70 bg-black/20 h-02"></div>
              </div>
            </div>

            <div className="flex mt-5">
              <div className="flex w-12 h-12 bg-white rounded-full shadow-button_shadow m-auto self-center mr-6">
                <img
                  className="self-center m-auto"
                  src={Contact_location}
                  alt=""
                />
              </div>

              <div className="mt-5">
                <h2 className="text-black/40 font-semibold text-base">
                  Visit Office
                </h2>
                <h2 className="text-black font-semibold sm:text-lg xs:text-sm mt-1">
                  D-2 204, Atul First Gate, Valsad, Gujrat.
                </h2>
                <div className="mt-4 sm:w-94 xs:w-70 bg-black/20 h-02"></div>
              </div>
            </div>
          </div>
          </Fade>

          <Fade right>
          <div className="flex-col pt-6 m-16 sm:w-142 xs:w-80 sm:h-128 xs:h-150 bg-retechgen-blue shadow-form_shadow relative rounded-md">
            <div className="xs:hidden sm:block absolute -left-16 top-10">
              <img src={Contact_Clip} alt="" />
            </div>

            <h2 className="text-white font-semibold text-lg text-center">
              Reach out to us!
            </h2>

            <form
              className="  m-auto mt-4 sm:w-120 xs:w-70 flex xs:column-1 sm:column-2 flex-wrap sm:px-0 xs:px-4"
              action="https://api.sheetmonkey.io/form/oRhvNyLcFJTQPSZjAMLpf3"
              method="post"
            >
              <input
                className="m-4 p-3 h-12 w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] "
                type="text"
                placeholder="First Name"
                name="f_name"
                id="f_name"
              />

              <input
                className="m-4 p-3 h-12 w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] "
                type="text"
                placeholder="Last Name"
                name="l_name"
                id="l_name"
              />

              <input
                className="m-4 p-3 h-12 w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] "
                type="number"
                placeholder="Mobile Number"
                name="number"
                id="number"
              />

              <input
                className="m-4 p-3 h-12 w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] "
                type="email"
                placeholder="Email"
                name="email"
                id="email"
              />

              <textarea
                className="mt-5 m-4 p-3 h-36 sm:w-112 xs:w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] "
                name="message"
                id="message"
                placeholder="Enter Your Message"
              ></textarea>

              <button className="mt-4 ml-4 hover:bg-[#0f778e] flex h-12 w-36 border-white border-solid border-2  text-white rounded-2xl text-sm ">
                <p className=" self-center m-auto font-semibold">Send</p>
              </button>
            </form>
          </div>
          </Fade>
        </div>
      </section>

      <Maps />
      <Footer />
    </>
  );
}

export default ContactPage;
