import React from "react";
import Navbar from "../../shared/Header/Navbar";
import { Link } from "react-router-dom";

import ProductsBg from "../../icons/Products_bg.svg";
import ProductsBgUpper from "../../icons/Products_bg_upper.svg";
import Footer from "../../shared/Footer/Footer";
import ProductLeftArt from "../../icons/product_left_art.svg";
import Touch_left from "../../icons/touch_left.svg";
import QIS_ProductCard from "../../icons/QIS_ProductCard.svg";
import Fade from "react-reveal/Fade";

function ProductsPage() {
  return (
    <>
      <Navbar />
      {window.scrollTo(0, 0)}

      {/* Landing Page */}
      <div>
        <img
          className="absolute left-0 bottom-4 h-80"
          src={ProductLeftArt}
          alt=""
        />

        <div className="flex justify-evenly absolute top-56 w-screen flex-wrap">
          <Fade top>
            <div className=" self-center pb-32 pt-10">
              <h2 className="xs:text-[#B51A8A]/90 sm:text-4xl xs:text-3xl font-bold md:text-[#f2f2f2] inline-block m-2">
                We are going to Launch
              </h2>{" "}
              <br />
              <h2 className="xs:text-[#B51A8A]/90 sm:text-4xl xs:text-3xl font-bold md:text-[#f2f2f2] inline-block m-2">
                something exiting soon...
              </h2>
            </div>
          </Fade>

          <Fade right>
            <div className="w-150 self-center xs:px-10 lg:px-0 sm:pl-20">
              <img src={ProductsBgUpper} alt="" />
            </div>
          </Fade>
        </div>

        <img
          className="xs:h-[56rem] xl:h-170 m-auto object-cover"
          src={ProductsBg}
          alt=""
        />
      </div>

      {/* QIS */}
      <section className="relative bg-[#DAF7FE]/30 py-24">
        <img
          className="absolute left-0 -bottom-18 h-80"
          src={Touch_left}
          alt=""
        />

        <div className="flex justify-evenly flex-wrap relative">
          <Fade bottom>
            <div className="m-10 mx-20 flex xs:mt-0 sm:-mt-20">
              <div className="absolute -left-1 top-20 bg-[#D9D9D9]/20 h-12 w-12 rounded-md"></div>
              <div className="absolute left-8 top-36 bg-[#D9D9D9]/20 h-8 w-8 rounded-md"></div>
              <div className="self-center mx-auto mt-1 mb-4 bg-retechgen-blue w-1 h-40 mr-8"></div>
              <h2 className="self-center text-left font-bold text-[#1F2347] text-3xl leading-10">
                Our Products
                <p className="xs:w-72 sm:w-96 text-left font-medium text-black/60 text-base leading-7 mt-2">
                  Our dedicated and qualified professionals are continuously
                  exploring &innovating to avail our elite clients with best in
                  class.
                </p>
              </h2>
            </div>
          </Fade>

          <Fade right>
            <div className=" mt-14 flex relative flex-col w-80 h-[25rem] pb-6 bg-white shadow-card_shadow rounded-lg">
              <img className="" src={QIS_ProductCard} alt="" />
              <h2 className="text-[#1F2347] font-semibold text-2xl mx-auto mt-6">
                QIS
              </h2>
              <p className="p-5 text-black/70 font-medium text-sm leading-6 mx-auto flex">
                Quick and Easy Insurance Solutions
              </p>

              <Link className="m-auto" to="/products/qis">
                <button className="bg-retechgen-blue w-36 h-10 rounded-md m-auto pt-0 shadow-button_shadow">
                  <span className=" text-white">See Product</span>
                </button>
              </Link>
            </div>
          </Fade>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default ProductsPage;
