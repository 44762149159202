import React from "react";
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';


const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoibWF5b2ppY2giLCJhIjoiY2pla3Q3MzVvMWRoYTJybnVyMndxM2hmdCJ9.nWZlYcpKaMqz6m7xTsnJGA"
});


function Maps() {
  return (
    <div className="">
      <Map
        style="mapbox://styles/mapbox/streets-v8"
        zoom={[6]}
        center={[71.1924, 22.2587]}
        containerStyle={{
          height: "60vh",
          width: "99vw"
        }}
        >
        <Layer type="symbol" id="marker" layout={{ "icon-image": "marker-15" }}>
          <Feature coordinates={[71.1924, 22.2587]} />
        </Layer>
      </Map>
    </div>
  )
}


export default Maps