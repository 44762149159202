import React from "react";
import Navbar from "../../shared/Header/Navbar";
import ServicesBg from "../../icons/ServicesBg.svg";
import WebsiteDevelopment from "../../icons/WebsiteDevelopment.svg";
import ApplicationServices from "../../icons/ApplicationServices.svg";
import CloudServices from "../../icons/CloudServices.svg";
import SoftwareServices from "../../icons/SoftwareServices.svg";
import ServicesRight from "../../icons/ServicesRight.svg";
import WebServices from "../../icons/WebServices.svg";
import ApplicationServicesMain from "../../icons/ApplicationServicesMain.svg";
import SoftwareServicesMain from "../../icons/SoftwareServicesMain.svg";
import CloudServicesMain from "../../icons/CloudServicesMain.svg";
import Touch_left from "../../icons/touch_left.svg";
import Touch_right from "../../icons/touch_right.svg";
import Touch_gps from "../../icons/touch_gps.svg";
import Touch_mail from "../../icons/touch_mail.svg";
import Touch_call from "../../icons/touch_call.svg";
import VirtualizationServices from "../../icons/virtualizationServices.svg";
import SecurityServicesMain from "../../icons/SecurityServicesMain.svg";
import ITConsultant from "../../icons/ITConsultant.svg";
import Footer from "../../shared/Footer/Footer";
import "./ServicesPage.css";
import Fade from "react-reveal/Fade";

function ServicesPage() {
  return (
    <div>
      <Navbar />
      {window.scrollTo(0, 0)}
      <div className=" mt-18 relative" style={{ height: "34.5rem" }}>
        <img
          className=" w-screen absolute z-0 h-[inherit] object-cover"
          src={ServicesBg}
          alt="About Us"
        />
        <div className="  absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Fade bottom>
            <h1 className="text-5xl font-bold text-white text-center">
              Our Services
            </h1>
            <p className="text-white text-base text-center mt-5">
              Developing unique, reliable and scalable software solutions for
              any OS, browser and device is the core of our software
              development. We amalgamate industry expertise and the latest IT
              advancements to deliver custom solutions and products that
              perfectly fit the needs and behaviour of the clients & their
              users.
            </p>
          </Fade>
        </div>
      </div>
      {/* Why Choose Us Section */}

      <section className=" relative flex flex-wrap justify-evenly xs:py-8 sm:py-14 xs:pt-20 sm:pt-36 bg-[#eeeeee7a]">
        <img
          className="absolute right-0 top-8 h-80"
          src={ServicesRight}
          alt=""
        />
        <div className="flex relative w-132 flex-wrap xs:mx-[8rem] sm:mx-0">
          <div className="flex-col flex-wrap">
            <Fade bottom duration={2000}>
              <div className="flex flex-wrap w-52 h-52 bg-white rounded-lg shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out m-10 hover:shadow-tile_hover_shadow">
                <div className="flex-col m-auto  ">
                  <img
                    className="m-auto mb-4 mt-4"
                    src={WebsiteDevelopment}
                    alt=""
                  />
                  <h2 className="mb-4 p-2 text-center text-theme-blue-text font-semibold text-lg">
                    Website Development
                  </h2>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="flex flex-wrap w-52 h-52 bg-white rounded-lg shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out m-10 hover:shadow-tile_hover_shadow">
                <div className="flex-col m-auto  ">
                  <img
                    className="m-auto mb-4 mt-4"
                    src={SoftwareServices}
                    alt=""
                  />
                  <h2 className="mb-4 p-2 text-center text-theme-blue-text font-semibold text-lg">
                    Software
                    <br /> Services
                  </h2>
                </div>
              </div>
            </Fade>
          </div>

          <div className="flex-col sm:absolute xs:-mt-10 sm:mt-0 sm:bottom-12  sm:left-70 xs:flex-wrap">
            <Fade bottom duration={3000}>
              <div className="flex w-52 h-52 bg-white rounded-lg shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out m-10 hover:shadow-tile_hover_shadow">
                <div className="flex-col m-auto  ">
                  <img
                    className="m-auto mb-4 mt-4"
                    src={ApplicationServices}
                    alt=""
                  />
                  <h2 className="mb-4 p-2 text-center text-theme-blue-text font-semibold text-lg">
                    Application <br /> Services
                  </h2>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={3000}>
              <div className="flex w-52 h-52 bg-white rounded-lg shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out m-10 hover:shadow-tile_hover_shadow">
                <div className="flex-col m-auto  ">
                  <img
                    className="m-auto mb-4 mt-4"
                    src={CloudServices}
                    alt=""
                  />
                  <h2 className="mb-4 p-2 text-center text-theme-blue-text font-semibold text-lg">
                    Cloud
                    <br /> Services
                  </h2>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className="flex-col m-10 p-10 mt-12">
          <h2 className="font-bold text-black text-4xl">
            We are{" "}
            <p className=" text-retechgen-blue inline-block">dedicated</p> to
            <br /> serve all time.
          </h2>
          <h2 className="text-black font-medium text-lg mt-6">
            From finance, retail, and travel, to social media,
            <br /> cybersecurity, adtech, and more, market leaders
            <br /> are leveraging web data to maintain their
            <br /> advantage. Discover how it can work for you.
          </h2>
        </div>
      </section>

      {/* What we offer you */}
      <section className="xs:pb-20 sm:pb-30 ">
        <div className="flex flex-col pt-16 ">
          <h2 className=" m-auto font-bold text-[#1F2347] text-3xl">
            What We <p className=" text-retechgen-blue inline-block">Offer</p>{" "}
            You
          </h2>
          <div className=" m-auto flex flex-col items-center h-1 w-70 bg-[#1B8DA6] mt-4"></div>
        </div>
        <div className="xs:mt-10 sm:mt-20">
          <div className="serviceContainer flex flex-wrap min-h-[460px] items-center justify-around ">
            <div className="servicesImage w-[31rem] h-[19rem]">
              <Fade left duration={2000}>
                <img src={WebServices} className=" w-full h-full" alt="" />
              </Fade>
            </div>
            <div className="line w-1 h-[inherit] min-h-[460px] relative bg-[#1b8da63b]">
              <div className=" w-6 h-6 rounded-[50%] absolute top-[10%] left-[-9px] border-4 z-10 border-solid border-[#1B8DA6]/[76%] bg-white"></div>
            </div>
            <div className="servicesContent w-[35rem]">
              <Fade right duration={2000}>
                <h2 className="font-bold text-[#1F2347] text-3xl">
                  Web Services
                </h2>
                <p className=" mt-6">
                  We are equipped with an excellent team of web development
                  experts who are constantly experimenting and implementing
                  innovative ideas. Starting from basic website designs,
                  including CMS and online store building to highly complex
                  business website apps and design solutions, we customize the
                  best of web development solutions for you.
                </p>
                <h4 className="mt-6 font-bold text-[#1F2347] text-2xl">
                  Our Web Development Services
                </h4>
                <ul className="webDServices mt-6 w-96 flex flex-wrap justify-between list-disc">
                  <li className=" mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Website Design
                    </p>
                  </li>
                  <li className="mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      CMS Development
                    </p>
                  </li>
                  <li className="mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Custom App Development
                    </p>
                  </li>
                  <li className="mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      MEAN/MERN stack
                    </p>
                  </li>
                </ul>
              </Fade>
            </div>
          </div>
          <div className="serviceContainer flex flex-wrap-reverse min-h-[460px] items-center justify-around ">
            <div className="servicesContent w-[27rem]">
              <Fade left duration={2000}>
                <h2 className="font-bold text-[#1F2347] text-3xl">
                  Application Services
                </h2>
                <p className=" mt-6">
                  Creating trendy, easy-to-use, seamless, and intuitive mobile
                  applications that offer the best user experience is what our
                  team of mobile app development professionals at Retechgen is
                  pro at. We design & develop all kinds of mobile apps for iOS,
                  Android, etc. depending on your business and end-user
                  requirements.
                </p>
                <h4 className="mt-6 font-bold text-[#1F2347] text-2xl">
                  Our Application Development Services
                </h4>
                <ul className="webDServices mt-6 w-[30rem] flex flex-wrap list-disc">
                  <li className=" mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Android App Development
                    </p>
                  </li>
                  <li className="mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      ionic App Development
                    </p>
                  </li>
                  <li className="mx-4 iOS">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      iOS App Development
                    </p>
                  </li>
                  <li className="ml-[3.1rem] PhoneGasApps">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Phone Gap Apps
                    </p>
                  </li>
                  <li className="mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      React Native Apps
                    </p>
                  </li>
                  <li className=" ml-[4.6rem] Xamarin">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Xamarin App Development
                    </p>
                  </li>
                </ul>
              </Fade>
            </div>
            <div className="line w-1 h-[inherit] min-h-[460px] relative bg-[#1b8da63b]">
              <div className=" w-6 h-6 rounded-[50%] absolute top-[10%] left-[-9px] border-4 z-10 border-solid border-[#1B8DA6]/[76%] bg-white"></div>
            </div>
            <div className="servicesImage w-[31rem] h-[19rem]">
              <Fade right duration={2000}>
                <img
                  src={ApplicationServicesMain}
                  className=" w-full h-full"
                  alt=""
                />
              </Fade>
            </div>
          </div>
          <div className="serviceContainer flex flex-wrap min-h-[460px] items-center justify-around ">
            <div className="servicesImage w-[31rem] h-[19rem]">
              <Fade left duration={2000}>
                <img
                  src={SoftwareServicesMain}
                  className=" w-full h-full"
                  alt=""
                />
              </Fade>
            </div>
            <div className="line w-1 h-[inherit] min-h-[460px] relative bg-[#1b8da63b]">
              <div className=" w-6 h-6 rounded-[50%] absolute top-[10%] left-[-9px] border-4 z-10 border-solid border-[#1B8DA6]/[76%] bg-white"></div>
            </div>
            <div className="servicesContent w-[35rem]">
              <Fade right duration={2000}>
                <h2 className="font-bold text-[#1F2347] text-3xl">
                  Software Services
                </h2>
                <p className=" mt-6">
                  Software support services are generally technical support
                  services that are delivered for specific software products.
                  Software products and technologies covered under this category
                  include commercial and custom operating systems, application
                  software, and infrastructure software.
                </p>
                <h4 className="mt-6 font-bold text-[#1F2347] text-2xl">
                  Our Software Development Services
                </h4>
                <ul className="webDServices mt-6 w-[30rem] flex flex-wrap list-disc">
                  <li className=" mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Software Development
                    </p>
                  </li>
                  <li className="mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Custom Software Development
                    </p>
                  </li>
                  <li className="mx-4 softwareCon">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Software Consulting
                    </p>
                  </li>
                  <li className="mx-4 ml-[2.2rem] softwareInt">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Software Integration
                    </p>
                  </li>
                </ul>
              </Fade>
            </div>
          </div>
          <div className="serviceContainer flex flex-wrap-reverse min-h-[460px] items-center justify-around ">
            <div className="servicesContent w-[27rem]">
              <Fade left duration={2000}>
                <h2 className="font-bold text-[#1F2347] text-3xl">
                  Cloud Services
                </h2>
                <p className=" mt-6">
                  Cloud services have incredibly revolutionized computing, with
                  IaaS, PaaS, and especially SaaS, which has allowed businesses
                  to develop virtualized IT infrastructure and deliver software
                  through the cloud, independent of a user's operating system.
                </p>
                <h4 className="mt-6 font-bold text-[#1F2347] text-2xl">
                  Our Cloud Computing Services
                </h4>
                <ul className="webDServices mt-6 w-[27rem] flex flex-wrap list-disc">
                  <li className=" mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm microsoft">
                      Microsoft Azure
                    </p>
                  </li>
                  <li className="mx-4 ml-[4.5rem] oracle">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Oracle Cloud Infrastructure
                    </p>
                  </li>
                  <li className="mx-4 amazon">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Amazon Web Services
                    </p>
                  </li>
                  <li className="mx-6 ibm">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      IBM Cloud
                    </p>
                  </li>
                  <li className="mx-4 google">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Google Cloud
                    </p>
                  </li>
                  <li className="mx-4 ml-[5.38rem] office">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Office 365 and Google Suite
                    </p>
                  </li>
                </ul>
              </Fade>
            </div>
            <div className="line w-1 h-[inherit] min-h-[460px] relative bg-[#1b8da63b]">
              <div className=" w-6 h-6 rounded-[50%] absolute top-[10%] left-[-9px] border-4 z-10 border-solid border-[#1B8DA6]/[76%] bg-white"></div>
            </div>
            <div className="servicesImage w-[31rem] h-[19rem]">
              <Fade right duration={2000}>
                <img
                  src={CloudServicesMain}
                  className=" w-full h-full"
                  alt=""
                />
              </Fade>
            </div>
          </div>
          <div className="serviceContainer flex flex-wrap min-h-[460px] items-center justify-around ">
            <div className="servicesImage w-[31rem] h-[19rem]">
              <Fade left duration={2000}>
                <img
                  src={VirtualizationServices}
                  className=" w-full h-full"
                  alt=""
                />
              </Fade>
            </div>
            <div className="line w-1 h-[inherit] min-h-[460px] relative bg-[#1b8da63b]">
              <div className=" w-6 h-6 rounded-[50%] absolute top-[10%] left-[-9px] border-4 z-10 border-solid border-[#1B8DA6]/[76%] bg-white"></div>
            </div>
            <div className="servicesContent w-[35rem]">
              <Fade right duration={2000}>
                <h2 className="font-bold text-[#1F2347] text-3xl">
                  Virtualization Services
                </h2>
                <p className=" mt-6">
                  Virtualization is the process of running a virtual instance of
                  a computer system in a layer abstracted from the actual
                  hardware. It also allows for isolation, keeping programs
                  running inside of a virtual machine safe from the processes
                  taking place in another virtual machine on the same host.
                </p>
                <h4 className="mt-6 font-bold text-[#1F2347] text-2xl">
                  Our Virtualization Services
                </h4>
                <ul className="webDServices mt-6 w-120 flex flex-wrap list-disc">
                  <li className=" mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Server Virtualization
                    </p>
                  </li>
                  <li className="mx-4 ml-[4.8rem] application">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Application Virtualization
                    </p>
                  </li>
                  <li className="mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Storage Server Virtualization
                    </p>
                  </li>
                  <li className="mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Hosted Desktop Virtualization
                    </p>
                  </li>
                </ul>
              </Fade>
            </div>
          </div>
          <div className="serviceContainer flex flex-wrap-reverse min-h-[460px] items-center justify-around ">
            <div className="servicesContent w-[27rem]">
              <Fade left duration={2000}>
                <h2 className="font-bold text-[#1F2347] text-3xl">
                  Security Services
                </h2>
                <p className=" mt-6">
                  With advancing technology, cyber-attacks are on an increase
                  too. Cybercriminals can attack your IT infrastructure in
                  numerous ways. Security breaches in your, IT can lead you to
                  lose valuable time, data and customers. We deliver an entire
                  suite of security products that work together, to provide the
                  best security for your business.
                </p>
                <h4 className="mt-6 font-bold text-[#1F2347] text-2xl">
                  Our Security and Backup Services
                </h4>
                <ul className="webDServices mt-6 w-[27rem] flex flex-wrap list-disc">
                  <li className=" mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Routing and Switching
                    </p>
                  </li>
                  <li className="mx-4 mr-[7rem]">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Firewall
                    </p>
                  </li>
                  <li className="mx-4 mr-[9.2rem] nas">
                    <p className="text-[#1F2347] font-semibold text-sm">NAS</p>
                  </li>
                  <li className="mx-4 network">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Network Security
                    </p>
                  </li>
                  <li className="mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Authorization Services
                    </p>
                  </li>
                  <li className="mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Audits and Gap Analysis
                    </p>
                  </li>
                </ul>
              </Fade>
            </div>
            <div className="line w-1 h-[inherit] min-h-[460px] relative bg-[#1b8da63b]">
              <div className=" w-6 h-6 rounded-[50%] absolute top-[10%] left-[-9px] border-4 z-10 border-solid border-[#1B8DA6]/[76%] bg-white"></div>
            </div>
            <div className="servicesImage w-[31rem] h-[19rem]">
              <Fade right duration={2000}>
                <img
                  src={SecurityServicesMain}
                  className=" w-full h-full"
                  alt=""
                />
              </Fade>
            </div>
          </div>
          <div className="serviceContainer flex flex-wrap min-h-[460px] items-center justify-around ">
            <div className="servicesImage w-[31rem] h-[19rem]">
              <Fade left duration={2000}>
                <img src={ITConsultant} className=" w-full h-full" alt="" />
              </Fade>
            </div>
            <div className="line w-1 h-[inherit] min-h-[460px] relative bg-[#1b8da63b]">
              <div className=" w-6 h-6 rounded-[50%] absolute top-[10%] left-[-9px] border-4 z-10 border-solid border-[#1B8DA6]/[76%] bg-white"></div>
            </div>
            <div className="servicesContent w-[35rem]">
              <Fade right duration={2000}>
                <h2 className="font-bold text-[#1F2347] text-3xl">
                  IT Consultation and Support
                </h2>
                <p className=" mt-6">
                  To cope up with the competitive & technological changes, it is
                  essential for businesses to opt for IT consulting and update
                  it at regular intervals. We offer consultancy on holistic,
                  fast, and cost-effective optimization of IT systems and
                  processes to ensure tangible business outcomes.
                </p>
                <h4 className="mt-6 font-bold text-[#1F2347] text-2xl">
                  Benefits of IT Consulting with Retechgen
                </h4>
                <ul className="webDServices mt-6 w-[30rem] flex flex-wrap list-disc">
                  <li className=" mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      End to End IT Services
                    </p>
                  </li>
                  <li className="mx-4 ml-[5.5rem] customized">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Customized IT Solutions
                    </p>
                  </li>
                  <li className="mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Fast and desired service results
                    </p>
                  </li>
                  <li className="mx-4">
                    <p className="text-[#1F2347] font-semibold text-sm">
                      Cost-effective Approach
                    </p>
                  </li>
                </ul>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-theme-bg ">
        <img
          className="absolute left-0 -bottom-18 h-80"
          src={Touch_left}
          alt=""
        />
        <img className="absolute right-0 top-8 h-80" src={Touch_right} alt="" />

        <div className="flex-col">
          <h2 className="pt-8 text-center font-bold text-[#1F2347] text-2xl">
            Get Personalized Quote
          </h2>
          <div className="mx-auto mt-1 mb-4 bg-retechgen-blue w-70 h-1"></div>
        </div>

        <div className="flex justify-evenly flex-wrap">
          <Fade left duration={2000}>
            <div className="m-10 mx-30 flex">
              <div className="self-center mx-auto mt-1 mb-4 bg-black w-1 h-40 mr-8"></div>
              <h2 className="self-center text-left font-bold text-[#1F2347] text-3xl leading-10">
                Write Us about your
                <h2 className=" text-left font-bold text-retechgen-blue/90 text-3xl leading-10">
                  Requirements.
                </h2>
              </h2>
            </div>
          </Fade>

          <div className="flex-col md:ml-40 xl:ml-20 pt-5 sm:m-16 xs:mt-96 sm:mt-8 md:w-150 lg:w-160 sm:h-128 xs:h-150 bg-white shadow-form_shadow relative ">
            <div className="bg-retechgen-blue w-70 h-80 rounded-md flex flex-col justify-center absolute sm:-left-36 sm:top-24 xs:left-13 xs:-top-88">
              <div className="flex-col ">
                <div className="ml-5 my-10 flex h-7">
                  <img src={Touch_gps} alt="" />
                  <h2 className="ml-3 text-white font-medium">Gujrat, India</h2>
                </div>

                <div className="ml-5 my-10 flex h-7">
                  <img className="h-5" src={Touch_mail} alt="" />
                  <h2 className="ml-3 text-white font-medium">
                      ajit.painuly92@gmail.com
                  </h2>
                </div>

                <div className="ml-5 my-10 flex h-7">
                  <img src={Touch_call} alt="" />
                  <h2 className="ml-3 text-white font-medium">9027875514</h2>
                </div>
              </div>
            </div>

            <form
              className=" xs:ml-16 xs:mr-16 sm:mr-0 sm:ml-48 mt-8 xs:w-90 sm:w-128 flex column-2 flex-wrap"
              action="https://api.sheetmonkey.io/form/oRhvNyLcFJTQPSZjAMLpf3"
              method="post"
            >
              <input
                className="m-4 p-3 h-12 w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] bg-[#EEEEEE7A]"
                type="text"
                placeholder="First Name"
                name="f_name"
                id="f_name"
              />

              <input
                className="m-4 p-3 h-12 w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] bg-[#EEEEEE7A]"
                type="text"
                placeholder="Last Name"
                name="l_name"
                id="l_name"
              />

              <input
                className="m-4 p-3 h-12 w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] bg-[#EEEEEE7A]"
                type="number"
                placeholder="Mobile Number"
                name="number"
                id="number"
              />

              <input
                className="m-4 p-3 h-12 w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] bg-[#EEEEEE7A]"
                type="email"
                placeholder="Email"
                name="email"
                id="email"
              />

              <textarea
                className="mt-5 m-4 p-3 h-36 lg:w-112 xs:w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] bg-[#EEEEEE7A]"
                name="message"
                id="message"
                placeholder="Enter Your Message"
              ></textarea>

              <button className="mt-4 ml-4 hover:bg-retechgen-blue flex h-12 w-36 bg-theme-blue text-white rounded-2xl text-sm shadow-button_shadow">
                <p className=" self-center m-auto font-semibold">Send</p>
              </button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ServicesPage;
