import React, { useState } from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

const Modal = ({ children, openButtonText }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  return (
    <>
      <button className="self-center m-auto font-semibold" onClick={openModal}>{openButtonText}</button>
      <ReactModal isOpen={isOpen} onRequestClose={closeModal}>
        <button onClick={closeModal} className="mt-10 hover:bg-retechgen-blue flex h-12 w-36 bg-theme-blue text-white rounded-2xl text-sm shadow-button_shadow"><p className=" self-center m-auto font-semibold">Close</p></button>
        <div>{children}</div>
      </ReactModal>
    </>
  );
};

export default Modal;
