import React from "react";
import Navbar from "../../shared/Header/Navbar";
import AboutUs from "../../icons/aboutUs.svg";
import AboutLeft from "../../icons/AboutLeft.svg";
import AboutRight from "../../icons/AboutRight.svg";
import WhoWeAre from "../../icons/WhoWeAre.svg";
import Card1 from "../../icons/aboutCard1.svg";
import Card2 from "../../icons/aboutCard2.svg";
import Card3 from "../../icons/aboutCard3.svg";
import ContactPerson from "../../icons/contactPerson.svg";
import ContactBackground from "../../icons/contactBg.svg";
import Star from "../../icons/star.svg";
import PhoneIcon from "../../icons/phoneIcon.svg";
import EmailIcon from "../../icons/emailIcon.svg";
import Introduction from "../../icons/Introduction.svg";
import "./AboutPage.css";
import Footer from "../../shared/Footer/Footer";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import Swing from "react-reveal/Swing";
import Roll from "react-reveal/Roll";
function AboutPage() {
  return (
    <div>
      <Navbar />
      {window.scrollTo(0, 0)}

      <div className=" mt-18 relative" style={{ height: "34.5rem" }}>
        <img
          className=" w-screen absolute z-0 h-[inherit] object-cover"
          src={AboutUs}
          alt="About Us"
        />
        <div className="  absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Fade up>
          <h1 className="text-5xl font-bold text-white text-center">
            About Retechgen
          </h1>
          <p className="text-white text-base text-center mt-5">
            Our dedicated and qualified professionals are continuously exploring
            &nbsp; innovating to avail our elite clients with best in class,
            reliable and prompt IT consulting, software development, design
            services.
          </p>
        </Fade>
        </div>
      </div>
      {/* Who We Are */}

      <div className="flex flex-col items-center mt-20">
        <h1 className="text-4xl text-[#1F2347] font-bold text-center">
          Who We Are
        </h1>
        <div className="flex flex-col items-center h-1 w-56 bg-[#1B8DA6] mt-6"></div>
      </div>
      {/* Introduction */}
      <section className="relative mt-10">
        <div className="flex h-[inherit] justify-center flex-wrap pt-12">
          <div className="h-[inherit] w-1/2 min-w-[320px] flex justify-center items-center">
            <Fade left duration={2000}>
            <img className="w-[67%]" src={Introduction} alt="" />
            </Fade>
          </div>
          <div className="introContent relative flex flex-col h-[inherit] min-w-[320px] w-1/2 justify-center items-center">
              <div className="absolute left-24 top-18 bg-[#D9D9D9]/20 h-12 w-12 rounded-md"></div>
              <div className="absolute left-32 top-36 bg-[#D9D9D9]/20 h-8 w-8 rounded-md"></div>
            <div className="flex flex-col  min-w-[238px] w-[63%]">
              <Fade right duration={2000}>
              <p className=" text-[#b51a8a9e] font-semibold">Our Information</p>
              <h2 className="font-bold text-[#1F2347] text-4xl mb-4">
                Introduction
              </h2>
              <p className=" text-black/70 font-medium text-medium leading-8">
                We are a leading IT development & support company that provides
                cutting edge solutions, enabling companies and enterprise
                clients to overcome complex issues that always emerge during
                their evolution to transformation journey. We deliver
                consistent, compelling, innovative, and agile IT solutions to
                businesses across the globe.
              </p>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      {/* Our Strength */}
      <section className="relative bg-[#F8F9FA] mt-10">
        <img
          className="absolute left-0 -bottom-10 h-80"
          src={AboutLeft}
          alt=""
        />
        <img className="absolute right-0 top-8 h-80" src={AboutRight} alt="" />

        <div className="flex flex-wrap justify-center pt-12">
          <div className="h-[inherit] w-1/2 min-w-[320px] flex justify-center items-center">
            <Slide left duration={2000}>
            <img className="w-[67%]" src={WhoWeAre} alt="" />
            </Slide>
          </div>
          <div className="introContent flex flex-col h-[inherit] min-w-[320px] w-1/2 justify-center items-center">
            <div className="flex flex-col  min-w-[238px] w-[63%]">
              <Slide right duration={2000}>
              <div className="font-bold text-[#1F2347] text-4xl">
                Our Strength
              </div>
              <div className="mt-1 mb-4 bg-theme-blue w-[15rem] h-1"></div>
              <p className=" text-black/70 font-medium text-medium leading-8">
                Our key strengths are our customers, core values, design&
                technology-driven innovation, agile mindset, operational
                efficiency, technology expertise, and quality services and
                support.
              </p>
              </Slide>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose US */}
      <section className=" pb-30 bg-[#1b8da60f]">
        <div className="flex flex-col pt-16 ">
          <h2 className=" m-auto font-bold text-[#1F2347] text-3xl">
            Why Choose Us!
          </h2>
          <div className=" m-auto flex flex-col items-center h-1 w-64 bg-[#1B8DA6] mt-4"></div>
        </div>
        <div className="flex flex-wrap  justify-around px-28">
        <Fade bottom duration={1000}>
          <div className="flex relative mt-24 flex-col w-[320px] min-w-[305px] min-h-[22.5rem] pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" h-16 mt-10" src={Card1} alt="" />
            <h2 className="text-[#1F2347] font-semibold text-lg mx-auto mt-6">
              Prompt &amp; reliable services
            </h2>
            <p className="p-9 text-black/70 font-medium text-sm leading-6">
              Our dedicated and qualified professionals are continuously
              exploring &amp; innovating to avail our elite clients with best in
              class, reliable and prompt IT consulting.
            </p>
          </div>
          </Fade>
          <Fade bottom duration={2000}>
          <div className="flex relative mt-24 flex-col w-80 min-w-[305px] h-94 pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" h-16 mt-10" src={Card2} alt="" />
            <h2 className="text-[#1F2347] font-semibold text-lg mx-auto mt-6">
              ITIL Process Encompass
            </h2>
            <p className="p-9 text-black/70 font-medium text-sm leading-6">
              Software development, design services, custom products, mobility
              and cloud operations with end-to-end customization.
            </p>
          </div>
          </Fade>
          <Fade bottom duration={3000}>
          <div className="flex relative mt-24 flex-col w-80 min-w-[305px] h-94 pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" h-16 mt-10" src={Card3} alt="" />
            <h2 className="text-[#1F2347] font-semibold text-lg mx-auto mt-6">
              Certified Professionals
            </h2>
            <p className="p-9 text-black/70 font-medium text-sm leading-6">
              Our solutions have empowered businesses across varied industry
              verticals leading them towards growth &nbsp; achieve a competitive
              position in the market.
            </p>
          </div>
        </Fade>
        </div>
      </section>

      {/* Contact Details */}
      <section className="relative h-120">
        <img
          className="absolute h-full object-cover w-full z-[-1]"
          src={ContactBackground}
          alt=""
        />
        <div className="flex flex-wrap w-full h-full">
          <div className="contactImage z-20 h-full w-1/2">
            <div className="contact-image h-[inherit] flex">
        <Fade left duration={2000}>
              <img className=" " src={ContactPerson} alt="" />
        </Fade>
            </div>
          </div>
          <div className="m-auto flex justify-center items-center z-20 h-full w-1/2">
            <Slide right duration={2000}>
            <div className=" flex flex-col justify-around h-[inherit]">
              <div>
                <h1 className=" font-semibold text-3xl text-center mb-[10px]">
                  4.9 / 5.0
                </h1>
                <div className="star flex justify-center">
                  <img className="h-8" src={Star} alt="" />
                  <img className="h-8" src={Star} alt="" />
                  <img className="h-8" src={Star} alt="" />
                  <img className="h-8" src={Star} alt="" />
                  <img className="h-8" src={Star} alt="" />
                </div>
              </div>
              <div className="flex ">
                <img className=" mr-4" src={PhoneIcon} alt="" />
                <div>
                  <h4 className=" font-semibold text-xl text-[#000000b0]">
                    Call For Advice Now!
                  </h4>
                  <h2 className="font-semibold text-2xl">+91 9027875514</h2>
                </div>
              </div>
              <div className="flex w-[inherit]">
                <img className=" mr-4 w-[70%]" src={EmailIcon} alt="" />
                <div className=" w-[inherit]">
                  <h4 className=" font-semibold text-xl text-[#000000b0]">
                    Say Hello
                  </h4>
                  <p className="email font-semibold text-2xl text-[1.2rem]">
                      ajit.painuly92@gmail.com
                  </p>
                </div>
              </div>
            </div>
            </Slide>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default AboutPage;
