import React  from 'react';
import AboutPage from "../components/AboutPage";
import HomePage from "../components/HomePage/HomePage.jsx";
import ContactPage from "../components/ContactPage/ContactPage.jsx";
import ProductsPage from "../components/ProductsPage/ProductsPage";
import JoinusPage from "../components/JoinusPage/JoinusPage";
import ServicesPage from '../components/ServicesPage';
import ObjectivesPage from '../components/ObjectivesPage/ObjectivesPage';
import { HOME_PAGE , CONTACT_PAGE, ABOUT_PAGE, PRODUCTS_PAGE , JOINUS_PAGE, SERVICES_PAGE , OBJECTIVES_PAGE, QIS_PAGE} from "./constants";
import QisProductPage from '../components/ProductsPage/QisProductPage/QisProductPage';


const routeConfig = {
    routes: [
        {
            path: HOME_PAGE,
            exact: true,
            name: "Home Page",
            component: <HomePage/>,
        },
        {
            path: CONTACT_PAGE,
            exact: true,
            name: "Connect Page",
            component: <ContactPage/>,
        },
        {
            path: ABOUT_PAGE,
            exact: true,
            name: "About Page",
            component: <AboutPage/>,
        },
        {
            path: PRODUCTS_PAGE,
            exact: true,
            name: "Products Page",
            component: <ProductsPage/>,
        },
        {
            path: JOINUS_PAGE,
            exact: true,
            name: "Join Us Page",
            component: <JoinusPage/>,
        },
        {
            path: SERVICES_PAGE,
            exact: true,
            name: "Services Page",
            component: <ServicesPage/>,
        },
        {
            path: OBJECTIVES_PAGE,
            exact: true,
            name: "Objectives Page",
            component: <ObjectivesPage/>,
        },
        {
            path: QIS_PAGE,
            exact: true,
            name: "QIS Page",
            component: <QisProductPage/>,
        },
    ],
}
export default routeConfig;