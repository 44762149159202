import React from "react";
import Navbar from "../../shared/Header/Navbar";
import home_bg from "../../icons/home_bg.svg";
import home_graphic_main from "../../icons/home_graphic_main.svg";
import mission from "../../icons/mission.svg";
import vision from "../../icons/vision.svg";
import focus from "../../icons/focus.svg";
import AboutLeft from "../../icons/AboutLeft.svg";
import AboutRight from "../../icons/AboutRight.svg";
import AboutComp from "../../icons/About_Comp.svg";
import Arrow_right from "../../icons/arrow_right.svg";
import Exp_Design from "../../icons/Home_exp_design.svg";
import Content_engineering from "../../icons/Home_content_engineering.svg";
import Data_structuring from "../../icons/Home_data_structuring.svg";
import Digital_service from "../../icons/Home_digital_service.svg";
import Team_ellipse from "../../icons/Team_ellipse.svg";
import Touch_left from "../../icons/touch_left.svg";
import Touch_right from "../../icons/touch_right.svg";
import Touch_gps from "../../icons/touch_gps.svg";
import Touch_mail from "../../icons/touch_mail.svg";
import Touch_call from "../../icons/touch_call.svg";
import Footer from "../../shared/Footer/Footer";
import Ajit from "../../icons/Ajit.jpg";
import Ashish from "../../icons/Ashish.png";
import Navidya from "../../icons/Navidya.jpeg";
import Neha from "../../icons/Neha.jpeg";
import Fb from "../../icons/fb.svg";
import Linkedin from "../../icons/linkedin.svg";
import Twitter from "../../icons/twitter.svg";
import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

function HomePage() {
  return (
    <>
      <Navbar />
      {window.scrollTo(0, 0)}

      {/* Landing Page */}
      <div className="relative lg:h-[44rem] xs:h-[60rem] sm:h-[65rem]">
        <img
          className=" w-screen absolute z-0 h-[inherit] object-cover"
          src={home_bg}
          alt=""
        />
        <div className=" xs:p-4 sm:p-0 absolute top-[12rem] flex self-center justify-around w-full flex-wrap z-20">
          <div className="w-120">
            <Fade top>
              <h2 className="md:mt-20 xs:mt-0 text-4xl font-bold text-theme-deep-blue inline-block">
                Redefining{" "}
                <p className="text-theme-text-blue inline-block">technology</p>{" "}
                to next generation
              </h2>
              <p className="mt-4 font-medium text-[#1F2347]">
                Our team of world-class consultants combine expertise in the
                industry, business processes and technology to help our
                customers solve complex business challenges and realize their
                strategic objectives.
              </p>
            </Fade>

            <Fade bottom>
              <Link to="/services">
                <button className="h-12 w-32 bg-theme-blue text-white font-semibold rounded-lg mt-10 text-sm">
                  Learn More
                </button>
              </Link>
            </Fade>
          </div>

          <div>
            <Slide right>
              <img
                className="xs:mt-20 md:mt-0 w-128"
                src={home_graphic_main}
                alt=""
              />
            </Slide>
          </div>
        </div>
      </div>

      {/* Objectives & Goals Section */}
      <section className=" pb-30 bg-theme-bg">
        <div className="flex flex-col pt-20 ">
          <h2 className="m-auto font-bold text-[#1F2347] text-2xl">Values</h2>
          <h2 className=" xs:text-center mt-4 m-auto text-3xl font-bold text-theme-deep-blue inline-block">
            Our <p className="text-theme-text-blue inline-block">Objectives</p>{" "}
            and <p className="text-theme-text-blue inline-block">Goals</p>
          </h2>
        </div>

        <Fade bottom>
          <div className="flex flex-wrap xs:mt-4 sm:mt-12 justify-around xs:px-6 sm:px-28">
            <div className=" mt-14 flex relative flex-col w-80 h-94 pb-6 bg-white shadow-card_shadow rounded-lg">
              <h2 className="text-[#1F2347] font-semibold text-2xl mx-auto mt-6">
                Mission
              </h2>
              <img className="h-13 mt-5" src={mission} alt="" />
              <p className="p-6 text-black/70 font-medium text-sm leading-6 text-center">
                Retechgen aims to deliver innovative development &amp; support
                solutions with speed and agility. Our technology independence,
                combined with a broad ecosystem of partners and lead technology
                talent enables us...{" "}
              </p>

              <Link to="/objectives">
                <div className="cursor-pointer hover:bg-[#1B8DA6] absolute -bottom-8 left-32 flex w-16 h-16  m-auto rounded-full shadow-card_circle_shadow bg-white">
                  <span className="hover:text-white text-retechgen-blue text-2xl self-center mx-auto p-6">
                    &gt;
                  </span>
                </div>
              </Link>
            </div>

            <div className=" mt-14 flex relative flex-col w-80 h-94 pb-6 bg-white shadow-card_shadow rounded-lg">
              <h2 className="text-[#1F2347] font-semibold text-2xl mx-auto mt-6">
                Vision
              </h2>
              <img className="h-16 mt-2" src={vision} alt="" />
              <p className="p-6 text-black/70 font-medium text-sm leading-6 text-center">
                To be amongst one of the leading IT company that not only caters
                to your development needs but also effectively provides support
                as and when required by blending latest technlogy, expertise and
                customer needs...
              </p>

              <Link to="/objectives">
                <div className="cursor-pointer hover:bg-[#1B8DA6] absolute -bottom-8 left-32 flex w-16 h-16  m-auto rounded-full shadow-card_circle_shadow bg-white">
                  <span className="hover:text-white text-retechgen-blue text-2xl self-center mx-auto p-6">
                    &gt;
                  </span>
                </div>
              </Link>
            </div>

            <div className=" mt-14 flex relative flex-col w-80 h-94 pb-6 bg-white shadow-card_shadow rounded-lg">
              <h2 className="text-[#1F2347] font-semibold text-2xl mx-auto mt-6">
                Focus
              </h2>
              <img className="h-16 mt-2" src={focus} alt="" />
              <p className="p-6 text-black/70 font-medium text-sm leading-6 text-center">
                Our main focus is to provide services and solution maintaining
                High-Quality standards, optimize customer satisfaction via
                executing customized projects according to customer’s need and
                redefining the existing...
              </p>

              <Link to="/objectives">
                <div className="cursor-pointer hover:bg-[#1B8DA6] absolute -bottom-8 left-32 flex w-16 h-16  m-auto rounded-full shadow-card_circle_shadow bg-white">
                  <span className="hover:text-white text-retechgen-blue text-2xl self-center mx-auto p-6">
                    &gt;
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </Fade>
      </section>

      {/* About Section */}
      <section className="relative">
        <img
          className="absolute left-0 -bottom-10 h-80"
          src={AboutLeft}
          alt=""
        />
        <img className="absolute right-0 top-8 h-80" src={AboutRight} alt="" />

        <div className="flex justify-evenly xs:pt-2 sm:pt-12 flex-wrap">
          <Fade left>
            <img className="m-10 xs:px-15 sm:ml-36 " src={AboutComp} alt="" />
          </Fade>
          <div className="flex-col xs:pt-0 sm:pt-5 m-16 w-112 lg:mr-36 xs:mr-16">
            <Fade right>
              <div>
                <h2 className="font-bold text-[#1F2347] text-3xl">About Us</h2>
                <div className="mt-1 mb-4 bg-theme-blue w-36 h-1"></div>
                <p className=" text-black/80 font-medium text-medium leading-8">
                  We are leading IT development &amp; support company that
                  provides cutting edge solution, enabling companies and
                  enterprise clients to overcome complex issues that always
                  emerge during their evolutoin to transformation journey. We
                  deliver consistent compling. Innovative, and agile IT solution
                  to business across the globe
                </p>
              </div>
            </Fade>

            <Fade right>
              <Link to="/about">
                <button className=" hover:bg-retechgen-blue flex h-10 w-28 bg-theme-blue text-white rounded-3xl mt-8 text-sm shadow-button_shadow">
                  <img
                    className=" self-center m-auto"
                    src={Arrow_right}
                    alt=""
                  />
                </button>
              </Link>
            </Fade>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}

      <section className="flex flex-wrap justify-evenly xs:py-10 sm:py-14 bg-[#EBE9EB]/30 xs:rounded-[3.5rem] sm:rounded-[10rem]">
        <div className="flex-col m-10 p-10 xs:mt-0 sm:mt-12">
          <Fade top>
            <div>
              <p className=" font-semibold text-theme-purple text-sm">
                Why Choose Us?
              </p>
              <h2 className="font-bold text-black text-4xl">
                <p className=" text-retechgen-blue inline-block">Innovating</p>{" "}
                with <br /> a{" "}
                <p className=" text-retechgen-blue inline-block">Digital</p>{" "}
                Mindset
              </h2>
              <h2 className="text-black font-medium text-lg mt-6">
                We are a boutique digital transformation <br /> consultancy and
                development company.
              </h2>
            </div>
          </Fade>

          <Fade bottom>
            <Link to="/contact">
              <button className="xs:mt-10 sm:mt-36 hover:bg-retechgen-blue flex h-12 w-36 bg-theme-blue text-white rounded-2xl text-sm shadow-button_shadow">
                <p className=" self-center m-auto font-semibold">
                  Get in Touch
                </p>
              </button>
            </Link>
          </Fade>
        </div>

        <div className="flex relative w-132 flex-wrap xs:mx-[8rem] sm:mx-0">
          <Fade right>
            <div className="xs:-mt-[2.6rem] sm:mt-0 flex-col flex-wrap">
              <div className="flex flex-wrap w-52 h-52 bg-white rounded-lg shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out m-10 hover:shadow-tile_hover_shadow">
                <div className="flex-col m-auto  ">
                  <img className="m-auto mb-5" src={Exp_Design} alt="" />
                  <h2 className="mb-4 text-center text-theme-blue-text font-medium text-lg">
                    Experience Design
                  </h2>
                </div>
              </div>

              <div className="flex flex-wrap w-52 h-52 bg-white rounded-lg shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out m-10 hover:shadow-tile_hover_shadow">
                <div className="flex-col m-auto  ">
                  <img
                    className="m-auto mb-5"
                    src={Content_engineering}
                    alt=""
                  />
                  <h2 className="mb-4 text-center text-theme-blue-text font-medium text-lg">
                    Content Engineering
                  </h2>
                </div>
              </div>
            </div>
          </Fade>

          <Fade right>
            <div className="flex-col sm:absolute xs:-mt-10 sm:mt-0 sm:bottom-12  sm:left-70 xs:flex-wrap">
              <div className="flex w-52 h-52 bg-white rounded-lg shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out m-10 hover:shadow-tile_hover_shadow">
                <div className="flex-col m-auto  ">
                  <img className="m-auto mb-5" src={Digital_service} alt="" />
                  <h2 className="mb-4 text-center text-theme-blue-text font-medium text-lg">
                    Digital Services
                  </h2>
                </div>
              </div>

              <div className="flex w-52 h-52 bg-white rounded-lg shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out m-10 hover:shadow-tile_hover_shadow">
                <div className="flex-col m-auto  ">
                  <img className="m-auto mb-5" src={Data_structuring} alt="" />
                  <h2 className="mb-4 text-center text-theme-blue-text font-medium text-lg">
                    Data Structuring
                  </h2>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>

      {/* Team */}

      <section className=" xs:pb-16 sm:pb-30 bg-theme-bg xs:mt-8 sm:mt-16 relative">
        <div className="flex flex-col pt-16 flex-wrap">
          <h2 className="m-auto font-bold text-[#000000]/60 text-sm">
            Team we have
          </h2>
          <h2 className="mt-4 m-auto xs:text-2xl sm:text-3xl font-normal text-theme-blue-text inline-block">
            Our Team Is Always Ready
          </h2>
          <h2 className="mt-1 m-auto text-3xl font-semibold text-theme-blue-text inline-block">
            To Solve Your Issues.
          </h2>
        </div>

        <div className="absolute left-0 bottom-10 w-40 ">
          <img src={Team_ellipse} alt="" />
        </div>

        <Fade bottom>
          <div className="flex mt-20 justify-around px-28 columns-3 flex-wrap box-border">
            <div className=" m-8 flex relative flex-col xs:px-16 sm:px-0 w-60 h-72 pb-6 bg-white shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out border-transparent border-2 border-solid hover:shadow-tile_hover_shadow rounded-lg">
              <div>
                <img
                  className="w-40 -mt-8 rounded-md m-auto"
                  src={Navidya}
                  alt="Navidya"
                />
                <h2 className="text-center text-theme-blue-text font-medium text-xl mt-7">
                  Navidya Chhetri
                </h2>
                <h2 className="text-center text-retechgen-blue font-normal text-sm mt-2">
                  Business Development
                </h2>

                <div className="flex mt-4 w-30 m-auto items-center">
                  <a href="https://www.facebook.com/navidhya.chhetri">
                    <img className="h-7 mx-2" src={Fb} style={{filter:"opacity(0.65"}} alt="" />
                  </a>
                  <a href="https://www.linkedin.com/in/navidhya-chhetri-04075823a/">
                    <img className="h-[1.4rem] mx-2" src={Linkedin} alt="" />
                  </a>
                  <a href="https://twitter.com/NavidhyaChhetri">
                    <img className="h-[1.9rem] mt-1 mx-2" src={Twitter} alt="" />
                  </a>
                </div>
              </div>
            </div>

            <div className=" m-8 flex relative flex-col xs:px-16 sm:px-0 w-60 h-72 pb-6 bg-white shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out border-transparent border-2 border-solid hover:shadow-tile_hover_shadow rounded-lg">
              <div>
                <img
                  className="w-40 -mt-8 rounded-md m-auto"
                  src={Ajit}
                  alt="Navidya"
                />
                <h2 className="text-center text-theme-blue-text font-medium text-xl mt-7">
                  Ajit Painuly
                </h2>
                <h2 className="text-center text-retechgen-blue font-normal text-sm mt-2">
                  Marketing Head
                </h2>

                <div className="flex mt-4 w-30 m-auto items-center">
                  <a href="https://www.facebook.com/ajack.ajit">
                    <img className="h-7 mx-2" style={{filter:"opacity(0.65"}} src={Fb} alt="" />
                  </a>
                  <a href="https://www.linkedin.com/in/ajit-kumar-painuly-bb3722108">
                    <img className="h-[1.4rem] mx-2" src={Linkedin} alt="" />
                  </a>
                  <a href="https://twitter.com/PainulyAjit">
                    <img className="h-[1.9rem] mt-1 mx-2" src={Twitter} alt="" />
                  </a>
                </div>
              </div>
            </div>

            <div className=" m-8 flex relative flex-col xs:px-16 sm:px-0 w-60 h-72 pb-6 bg-white shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out border-transparent border-2 border-solid hover:shadow-tile_hover_shadow rounded-lg">
              <div>
                <img
                  className="w-40 -mt-8 rounded-md m-auto"
                  src={Ashish}
                  alt="Navidya"
                />
                <h2 className="text-center text-theme-blue-text font-medium text-xl mt-7">
                  Ashish Chauhan
                </h2>
                <h2 className="text-center text-retechgen-blue font-normal text-sm mt-2">
                  IT Operations
                </h2>

                <div className="flex mt-4 w-30 m-auto items-center">
                  <a href="https://www.facebook.com/giniashi/">
                    <img className="h-7 mx-2" src={Fb} style={{filter:"opacity(0.65"}} alt="" />
                  </a>
                  <a href="https://www.linkedin.com/in/ashish-chauhan-31078b18">
                    <img className="h-[1.4rem] mx-2" src={Linkedin} alt="" />
                  </a>
                  <a href="https://twitter.com/giniashi007">
                    <img className="h-[1.9rem] mt-1 mx-2" src={Twitter} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>

      {/* Get In Touch */}
      <section className="relative bg-theme-bg ">
        <img
          className="absolute left-0 -bottom-18 h-80"
          src={Touch_left}
          alt=""
        />
        <img className="absolute right-0 top-8 h-80" src={Touch_right} alt="" />

        <div className="flex-col">
          <h2 className="pt-8 text-center font-bold text-[#1F2347] text-2xl">
            Get In Touch
          </h2>
          <div className="mx-auto mt-1 mb-4 bg-retechgen-blue w-40 h-1"></div>
        </div>

        <div className="flex justify-evenly flex-wrap">
          <Fade left>
            <div className="m-10 mx-30 flex">
              <div className="self-center mx-auto mt-1 mb-4 bg-black w-1 h-40 mr-8"></div>
              <h2 className="self-center text-left font-bold text-[#1F2347] text-3xl leading-10">
                To make requests for <br /> further information,
                <h2 className=" text-left font-bold text-retechgen-blue/90 text-3xl leading-10">
                  Contact us.
                </h2>
              </h2>
            </div>
          </Fade>

          <div className="flex-col md:ml-40 xl:ml-20 pt-5 sm:m-16 xs:mt-96 sm:mt-8 md:w-150 lg:w-160 sm:h-128 xs:h-150 bg-white shadow-form_shadow relative ">
            <div className="bg-retechgen-blue w-70 h-80 rounded-md flex flex-col justify-center absolute sm:-left-36 sm:top-24 xs:left-13 xs:-top-88">
              <div className="flex-col ">
                <div className="ml-5 my-10 flex h-7">
                  <img src={Touch_gps} alt="" />
                  <h2 className="ml-3 text-white font-medium">Gujrat, India</h2>
                </div>

                <div className="ml-5 my-10 flex h-7">
                  <img className="h-5" src={Touch_mail} alt="" />
                  <h2 className="ml-3 text-white font-medium">
                      ajit.painuly92@gmail.com
                  </h2>
                </div>

                <div className="ml-5 my-10 flex h-7">
                  <img src={Touch_call} alt="" />
                  <h2 className="ml-3 text-white font-medium">9027875514</h2>
                </div>
              </div>
            </div>

            <form
              className=" xs:ml-16 xs:mr-16 sm:mr-0 sm:ml-48 mt-8 xs:w-90 sm:w-128 flex column-2 flex-wrap"
              action="https://api.sheetmonkey.io/form/oRhvNyLcFJTQPSZjAMLpf3"
              method="post"
            >
              <input
                className="m-4 p-3 h-12 w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] bg-[#EEEEEE7A]"
                type="text"
                placeholder="First Name"
                name="f_name"
                id="f_name"
              />

              <input
                className="m-4 p-3 h-12 w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] bg-[#EEEEEE7A]"
                type="text"
                placeholder="Last Name"
                name="l_name"
                id="l_name"
              />

              <input
                className="m-4 p-3 h-12 w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] bg-[#EEEEEE7A]"
                type="number"
                placeholder="Mobile Number"
                name="number"
                id="number"
              />

              <input
                className="m-4 p-3 h-12 w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] bg-[#EEEEEE7A]"
                type="email"
                placeholder="Email"
                name="email"
                id="email"
              />

              <textarea
                className="mt-5 m-4 p-3 h-36 lg:w-112 xs:w-52 text-theme-gray2 font-medium border-[#1B8DA64F] border-solid border-2 rounded-md outline-1 outline-[#1b8da694] bg-[#EEEEEE7A]"
                name="message"
                id="message"
                placeholder="Enter Your Message"
              ></textarea>

              <button className="mt-4 ml-4 hover:bg-retechgen-blue flex h-12 w-36 bg-theme-blue text-white rounded-2xl text-sm shadow-button_shadow">
                <p className=" self-center m-auto font-semibold">Send</p>
              </button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default HomePage;
