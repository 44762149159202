import React, { useState, useEffect } from "react";
import Retechgen_Logo from "../../icons/Retechgen_Final_LOGO.png";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import CloseButton from "../../icons/CloseButton.svg";
import Whatsapp from "../../icons/whatsapp.svg";
import Facebook from "../../icons/facebook.svg";
import LinkedIn from "../../icons/linkedin.svg";
import Twitter from "../../icons/twitter.svg";

function Navbar() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [highlightHome, setHighlightHome] = useState(true);
  const [highlightAbout, setHighlightAbout] = useState(false);
  const [highlightServices, setHighlightServices] = useState(false);
  const [highlightProducts, setHighlightProducts] = useState(false);
  const [highlightContact, setHighlightContact] = useState(false);
  const location = useLocation();
  function sidebar() {
    setSidebarOpen(!sidebarOpen);
  }
  useEffect(() => {
    if (sidebarOpen) {
      document.getElementById("sidebarBox").style.transform =
        "translateX(100%)";
      setTimeout(() => {
        document.getElementById("sidebar").style.display = "none";
      }, 500);
    } else {
      document.getElementById("sidebar").style.display = "block";
      setTimeout(() => {
        document.getElementById("sidebarBox").style.transform =
          "translateX(0%)";
      }, 500);
    }
  }, [sidebarOpen]);
  useEffect(() => {
    if (location.pathname.includes(" ")) {
      setHighlightHome(true);
      setHighlightAbout(false);
      setHighlightServices(false);
      setHighlightProducts(false);
      setHighlightContact(false);
    } else if (location.pathname.includes("/about")) {
      setHighlightHome(false);
      setHighlightAbout(true);
      setHighlightServices(false);
      setHighlightProducts(false);
      setHighlightContact(false);
    } else if (location.pathname.includes("/services")) {
      setHighlightHome(false);
      setHighlightAbout(false);
      setHighlightServices(true);
      setHighlightProducts(false);
      setHighlightContact(false);
    } else if (location.pathname.includes("/products")) {
      setHighlightHome(false);
      setHighlightAbout(false);
      setHighlightServices(false);
      setHighlightProducts(true);
      setHighlightContact(false);
    } else if (location.pathname.includes("/contact")) {
      setHighlightHome(false);
      setHighlightAbout(false);
      setHighlightServices(false);
      setHighlightProducts(false);
      setHighlightContact(true);
    } else if (location.pathname.includes("/join_us")) {
      setHighlightHome(false);
      setHighlightAbout(false);
      setHighlightServices(false);
      setHighlightProducts(false);
      setHighlightContact(false);
    } else if (location.pathname.includes("/products/qis")) {
      setHighlightHome(false);
      setHighlightAbout(false);
      setHighlightServices(false);
      setHighlightProducts(true);
      setHighlightContact(false);
    }
  }, [location.pathname]);
  return (
    <>
      <navbar className=" z-50 top-0 w-full fixed h-20 flex  bg-white justify-between">
        <Link to="/">
          <img className=" h-13 m-4 ml-12" src={Retechgen_Logo} alt="Logo" />
        </Link>

        <div className="flex navItems">
          <ol className="flex self-center font-semibold text-black/60 mt-5">
            <Link to="/">
              <li
                className="m-5 p-3 hover:text-theme-hover-blue visited:text-theme-hover-blue cursor-pointer"
                style={
                  highlightHome
                    ? { color: "#229DB8" }
                    : { color: "rgba(0, 0, 0, 0.60)" }
                }
              >
                Home
              </li>
            </Link>
            <Link to="/about">
              <li
                className="m-5 p-3 hover:text-theme-hover-blue visited:text-theme-hover-blue cursor-pointer"
                style={
                  highlightAbout
                    ? { color: "#229DB8" }
                    : { color: "rgba(0, 0, 0, 0.60)" }
                }
              >
                About
              </li>
            </Link>
            <Link to="/services">
              <li
                className="m-5 p-3 hover:text-theme-hover-blue cursor-pointer"
                style={
                  highlightServices
                    ? { color: "#229DB8" }
                    : { color: "rgba(0, 0, 0, 0.60)" }
                }
              >
                Services
              </li>
            </Link>
            <Link to="/products">
              <li
                className="m-5 p-3 hover:text-theme-hover-blue cursor-pointer"
                style={
                  highlightProducts
                    ? { color: "#229DB8" }
                    : { color: "rgba(0, 0, 0, 0.60)" }
                }
              >
                Products
              </li>
            </Link>
            <Link to="/contact">
              <li
                className="m-5 p-3 hover:text-theme-hover-blue cursor-pointer"
                style={
                  highlightContact
                    ? { color: "#229DB8" }
                    : { color: "rgba(0, 0, 0, 0.60)" }
                }
              >
                Contact
              </li>
            </Link>
          </ol>
          <Link to="/join_us">
            <button className=" hover:bg-retechgen-blue mr-6 ml-10 h-8 w-22 bg-theme-blue text-white font-semibold rounded-md mt-8 text-sm">
              Join Us
            </button>
          </Link>
        </div>
        <div className="hamburger mr-4 mt-8">
          <svg
            viewBox="0 0 100 80"
            width="35"
            height="35"
            className=" cursor-pointer"
            onClick={sidebar}
          >
            <rect width="80" height="10"></rect>
            <rect y="20" width="80" height="10"></rect>
            <rect y="40" width="80" height="10"></rect>
          </svg>
        </div>
        <div
          className="sidebar fixed top-0 right-0 w-full h-full bg-[#2e2d2da3]"
          id="sidebar"
        >
          <div
            className="fixed top-0 right-0 w-[324px] h-full bg-[#1b8da624]"
            id="sidebarBox"
          >
            <div className="fixed w-[324px] h-full bg-white z-[-2] "></div>
            <div className="fixed w-[324px] h-full bg-[#1b8da624] z-[-1] "></div>
            <div className="flex bg-white">
              <Link to="/">
                <img
                  className=" h-12 m-4 ml-5 "
                  src={Retechgen_Logo}
                  alt="Logo"
                />
              </Link>
            </div>
            <img
              src={CloseButton}
              alt="Close"
              className="closeButton absolute top-7 right-6 h-7"
              onClick={sidebar}
            />
            <ul className="flex flex-col mt-4 ml-9">
              <Link to="/">
                <li
                  className="m-3 font-medium hover:text-theme-hover-blue cursor-pointer"
                  style={
                    highlightHome
                      ? { color: "#229DB8" }
                      : { color: "rgba(0, 0, 0, 0.80)" }
                  }
                >
                  Home
                </li>
              </Link>
              <div className=" w-52 ml-4 h-[1px] bg-[#26323888]"></div>
              <Link to="/about">
                <li className="m-3 font-medium hover:text-theme-hover-blue cursor-pointer"
                style={
                  highlightAbout
                    ? { color: "#229DB8" }
                    : { color: "rgba(0, 0, 0, 0.80)" }
                }
                >
                  About
                </li>
              </Link>
              <div className=" w-52 ml-4 h-[1px] bg-[#26323888]"></div>
              <Link to="/services">
                <li className="m-3 font-medium  hover:text-theme-hover-blue cursor-pointer"
                style={
                  highlightServices
                    ? { color: "#229DB8" }
                    : { color: "rgba(0, 0, 0, 0.80)" }
                }
                >
                  Services
                </li>
              </Link>
              <div className=" w-52 ml-4 h-[1px] bg-[#26323888]"></div>
              <Link to="/products">
                <li className="m-3 font-medium hover:text-theme-hover-blue cursor-pointer"
                 style={
                  highlightProducts
                    ? { color: "#229DB8" }
                    : { color: "rgba(0, 0, 0, 0.80)" }
                }
                >
                  Products
                </li>
              </Link>
              <div className=" w-52 ml-4 h-[1px] bg-[#26323888]"></div>
              <Link to="/contact">
                <li className="m-3 font-medium hover:text-theme-hover-blue cursor-pointer"
                style={
                  highlightContact
                    ? { color: "#229DB8" }
                    : { color: "rgba(0, 0, 0, 0.80)" }
                }
                >
                  Contact
                </li>
              </Link>
              <div className=" w-52 ml-4 h-[1px] bg-[#26323888]"></div>
              <Link to="/join_us">
                <li className="m-3 font-semibold hover:text-theme-hover-blue cursor-pointer">
                  Join Us
                </li>
              </Link>
              <div className=" w-52 ml-4 h-[1px] bg-[#26323888]"></div>
            </ul>
            <p className=" text-2xl ml-9 mt-8 font-semibold">Connect With</p>
            <p className=" text-lg ml-9 mt-2 text-[#263238db]">
              ajit.painuly92@gmail.com
            </p>
            <p className=" text-lg ml-9 mt-1 text-[#263238db]">
              +91 9027875514
            </p>
            <div className=" w-52 ml-4 mt-5 socials">
              <ul className="flex justify-around ">
                <li className=" border border-solid border-[#00000014] p-1 rounded-[50%] cursor-pointer">
                  <a href="https://www.facebook.com/retechgen" target="_blank">
                    <img className="px-1 h-6" src={Facebook} alt="Facebook" />
                  </a>
                </li>
                <li className=" border border-solid border-[#00000014] p-1 rounded-[50%] cursor-pointer">
                  <a href="https://www.linkedin.com/company/retechgen/">
                    <img className="h-[1.2rem]" src={LinkedIn} alt="LinkedIn" />
                  </a>
                </li>
                <li className=" border border-solid border-[#00000014] p-1 rounded-[50%] cursor-pointer">
                  <a href="https://twitter.com/retechgen">
                    <img className="h-6" src={Twitter} alt="Twitter" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </navbar>
    </>
  );
}

export default Navbar;
