import React from "react";
import Navbar from "../../shared/Header/Navbar";
import Footer from "../../shared/Footer/Footer";
import ObjectivesElement from "../../icons/Objectives_Element.svg";
import OurFocusElement from "../../icons/OurFocus_Element.svg";
import OurMissionElement from "../../icons/OurMission_left.svg";
import OurVisionElement from "../../icons/OurVision_Element.svg";
import Fade from "react-reveal/Fade";

function ObjectivesPage() {
  return (
    <div>
      <Navbar />
      {window.scrollTo(0, 0)}

      {/* Our Mission */}
      <section className="relative bg-retechgen-blue/5 xs:pb-10 sm:pb-24 py-24">
        <div className="flex justify-evenly flex-wrap mt-6">
          <Fade left>
            <img className="-ml-14" src={OurMissionElement} alt="" />
          </Fade>

          <Fade right>
            <div className="m-10 mx-20 flex-col">
              <div className="flex flex-col pt-16 ">
                <img
                  className="h-18 mr-[15rem]"
                  src={ObjectivesElement}
                  alt=""
                />
                <h2 className="mt-4 m-auto text-4xl font-bold text-theme-deep-blue inline-block">
                  Our{" "}
                  <p className="text-theme-text-blue inline-block">Mission</p>
                </h2>
                <div className="m-auto w-56 mt-3 h-1 bg-retechgen-blue"></div>
              </div>
              <h2 className="xs:w-72 sm:w-128 text-left font-medium text-[#3F3D56]/90 text-base leading-7 mt-8">
                Retechgen aims to deliver innovative development & support
                solutions with speed and agility. Our technology independence,
                combined with a broad ecosystem of partners and leading
                technology talent enables us to deliver maximum value to our
                customers across their enterprise technology investments.
              </h2>
            </div>
          </Fade>
        </div>
      </section>

      {/* Our Vision */}
      <section className="relative bg-[#F0F0F0]/10 xs:py-4 sm:py-36">
        <div className="flex justify-evenly flex-wrap">
          <Fade left>
            <div className="m-10 mx-20 flex-col">
              <div className="flex flex-col ">
                <img
                  className="h-18 mr-[15rem]"
                  src={ObjectivesElement}
                  alt=""
                />
                <h2 className="mt-4 m-auto text-4xl font-bold text-theme-deep-blue inline-block">
                  Our{" "}
                  <p className="text-theme-text-blue inline-block">Vision</p>
                </h2>
                <div className="m-auto w-56 mt-3 h-1 bg-retechgen-blue"></div>
              </div>
              <h2 className="xs:w-72 sm:w-128 text-left font-medium text-[#3F3D56]/90 text-base leading-7 mt-8">
                To be amongst one of the leading IT company that not only caters
                to your development needs but also effectively provides support
                as and when required by blending latest technology, expertise
                and customer needs.
              </h2>
            </div>
          </Fade>

          <Fade right>
            <img className="" src={OurVisionElement} alt="" />
          </Fade>
        </div>
      </section>

      {/* Our Focus */}
      <section className="relative bg-[#F0F0F0]/40 xs:py-6 sm:py-32">
        <div className="flex-wrap-reverse flex justify-evenly mt-6">
          <Fade left>
            <img className="" src={OurFocusElement} alt="" />
          </Fade>

          <Fade right>
            <div className="m-10 mx-20 flex-col">
              <div className="flex flex-col  ">
                <img
                  className="h-18 mr-[15rem]"
                  src={ObjectivesElement}
                  alt=""
                />
                <h2 className="mt-4 m-auto text-4xl font-bold text-theme-deep-blue inline-block">
                  Our <p className="text-theme-text-blue inline-block">Focus</p>
                </h2>
                <div className="m-auto w-56 mt-3 h-1 bg-retechgen-blue"></div>
              </div>
              <h2 className="xs:w-72 sm:w-128 text-left font-medium text-[#3F3D56]/90 text-base leading-7 mt-8">
                Our main focus is to provide services and solution maintaining
                High-Quality standards, optimize customer satisfaction via
                executing customized projects according to customer’s need and
                redefining the existing technology and using it effectively in
                an array of sectors thereby instilling ease andefficiency.
              </h2>
            </div>
          </Fade>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default ObjectivesPage;
