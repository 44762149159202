import React from "react";
import Navbar from "../../../shared/Header/Navbar";
import { Link } from "react-router-dom";
import "./QisProductPage.css";
import qisBackground from "../../../icons/qisBackground.svg";
import ServicesRight from "../../../icons/ServicesRight.svg";
import HealthInsurance from "../../../icons/healthInsurance.svg";
import Card1 from "../../../icons/TwoWheeler.svg";
import Card2 from "../../../icons/FourWheeler.svg";
import Card3 from "../../../icons/CommercialVehicles.svg";
import NMCard1 from "../../../icons/NMCard1.svg";
import NMCard1_2 from "../../../icons/NMCard1_2.svg";
import NMCard2 from "../../../icons/NMCard2.svg";
import NMCard2_2 from "../../../icons/NMCard2_2.svg";
import NMCard3 from "../../../icons/NMCard3.svg";
import NMCard3_2 from "../../../icons/NMCard3_2.svg";
import NMCard4 from "../../../icons/NMCard4.svg";
import NMCard4_2 from "../../../icons/NMCard4_2.svg";
import NMCard5 from "../../../icons/NMCard5.svg";
import NMCard5_2 from "../../../icons/NMCard5_2.svg";
import NMCard6 from "../../../icons/NMCard6.svg";
import NMCard6_2 from "../../../icons/NMCard6_2.svg";
import NMCard7 from "../../../icons/NMCard7.svg";
import NMCard7_2 from "../../../icons/NMCard7_2.svg";
import NMCard8 from "../../../icons/NMCard8.svg";
import NMCard8_2 from "../../../icons/NMCard8_2.svg";
import NMCard9 from "../../../icons/NMCard9.svg";
import NMCard9_2 from "../../../icons/NMCard9_2.svg";
import NMCard10 from "../../../icons/NMCard10.svg";
import NMCard10_2 from "../../../icons/NMCard10_2.svg";
import NMQuoteBackground from "../../../icons/NMQuoteBackground.svg";
import Polygon1 from "../../../icons/Polygon1.svg";
import Polygon2 from "../../../icons/Polygon2.svg";
import Polygon3 from "../../../icons/Polygon3.svg";
import Polygon4 from "../../../icons/Polygon4.svg";
import Footer from "../../../shared/Footer/Footer";
import StrongIndustry from "../../../icons/strongIndustry.svg";
import Team from "../../../icons/Team.svg";
import CustomerSupport from "../../../icons/CustomerSupport.svg";
import QuickestGrowth from "../../../icons/QuickestGrowth.svg";
import HealthInsuranceModal from "../QisProductPage/Modals/HealthInsuranceModal";
import MotorInsuranceModal from "../QisProductPage/Modals/MotorInsuranceModal";
import NonMotorInsuranceModal from "./Modals/NonMotorInsuranceModal";
import Carousel from "./Carousel ";




function QisProductPage() {
  return (
    <div>
      <Navbar />
      {window.scrollTo(0, 0)}
      <div className=" mt-18 relative flex" style={{ height: "34.5rem" }}>
        <img
          className=" w-screen absolute z-[-1] h-[inherit] object-cover"
          src={qisBackground}
          alt="Background"
        />
        <div className="qis max-w-[619px] h-[357px] bg-[#003478cf] flex items-center justify-center rounded-xl mt-[60px] ml-[150px]">
          <h1 className="text-5xl font-bold text-white text-center m-4 leading-[4rem]">
            Quick and Easy Insurance Solutions
          </h1>
        </div>
      </div>
      {/* Health Care */}
      <section className="relative bg-[#f8f9fa7a]">
        <img
          className="absolute right-0 top-[0rem] h-80"
          src={ServicesRight}
          alt=""
        />
        <div className="flex flex-col items-center ">
          <h1 className="text-4xl text-[#1F2347] font-bold mt-20 text-center">
            Health Care
          </h1>
          <div className="flex flex-col items-center h-1 w-56 bg-[#1B8DA6] mt-4"></div>
        </div>
        <div className="flex flex-col items-center mt-6">
          <h5 className="text-xl text-[#1F2347] font-semibold text-center">
            Our Healthcare Solutions
          </h5>
        </div>
        <div className="flex justify-evenly items-center flex-wrap">
          <div className="m-[2%] flex min-w-[360px] w-1/2">
            {/* <div className=" mt-1 mb-4 bg-[#1B8DA6] w-[25px] h-40 mr-8"></div> */}
            <div className="flex flex-col ">
              <div className=" p-4 border-l-4 border-[#1B8DA6] border-solid">
                <h2 className=" text-left font-bold text-[#1F2347] text-3xl leading-10">
                  Health Insurance
                </h2>
                <p className=" text-[#00000094]">
                  Health insurance or medical insurance is a contract between
                  the insurer and policyholder where the insurance company
                  offers medical coverage to the insured up to the sum insured
                  limit. Multiple benefits on health plans are available, like
                  coverage for pre and post hospitalization, medical check-ups,
                  room rent coverage, cashless facility, etc. Choose your health
                  plan now ! Get our customized quote as per your health needs.
                </p>
              </div>
              <button className="xs:mt-10 hover:bg-retechgen-blue flex h-12 w-36 bg-theme-blue text-white rounded-2xl text-sm shadow-button_shadow">
                <HealthInsuranceModal />
              </button>

            </div>
          </div>
          <div className=" h-[inherit] min-w-[360px] w-[45%] flex justify-center items-center">
            <img className="w-96 h-96" src={HealthInsurance} alt="" />
          </div>
        </div>
      </section>
      {/* Motor Products */}
      <section className="relative bg-[#daf7fe3d] mt-[39px] pb-30">
        <div className="flex flex-col items-center ">
          <h1 className="text-4xl text-[#1F2347] font-bold mt-20 text-center">
            Motor
          </h1>
          <div className="flex flex-col items-center h-1 w-32 bg-[#1B8DA6] mt-4"></div>
        </div>
        <div className="flex flex-col items-center mt-6">
          <h5 className="text-xl text-[#1F2347] font-semibold text-center">
            Our Motor Product
          </h5>
        </div>
        <div className="flex flex-wrap  justify-around px-28">
          <div className="flex relative mt-24 flex-col w-[320px] min-w-[305px] min-h-[22.5rem] pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" h-16 mt-10" src={Card1} alt="" />
            <h2 className="text-[#1F2347] font-semibold text-lg mx-auto mt-6">
              Two Wheeler
            </h2>
            <p className="p-9 text-black/70 font-medium text-sm leading-6">
              Our dedicated and qualified professionals are continuously
              exploring &innovating to avail our elite clients with best in
              class, reliable and prompt IT consulting.
            </p>
            <button className=" self-center hover:bg-retechgen-blue flex h-12 w-36 bg-theme-blue text-white rounded-2xl text-sm shadow-button_shadow">
              <MotorInsuranceModal />
            </button>
          </div>

          <div className="flex relative mt-24 flex-col w-80 min-w-[305px] min-h-94 pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" h-16 mt-10" src={Card2} alt="" />
            <h2 className="text-[#1F2347] font-semibold text-lg mx-auto mt-6">
              Four Wheeler
            </h2>
            <p className="p-9 text-black/70 font-medium text-sm leading-6">
              Software development, design services, custom products, mobility
              and cloud operations with end-to-end customization.
            </p>
            <button className="mt-6 self-center hover:bg-retechgen-blue flex h-12 w-36 bg-theme-blue text-white rounded-2xl text-sm shadow-button_shadow">
              <MotorInsuranceModal />
            </button>
          </div>

          <div className="flex relative mt-24 flex-col w-80 min-w-[305px] min-h-94 pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" h-16 mt-10" src={Card3} alt="" />
            <h2 className="text-[#1F2347] font-semibold text-lg mx-auto mt-6">
              Commercial Vehicles
            </h2>
            <p className="p-9 text-black/70 font-medium text-sm leading-6">
              Our solutions have empowered businesses across varied industry
              verticals leading them towards growth & achieve a competitive
              position in the market.
            </p>
            <button className=" self-center hover:bg-retechgen-blue flex h-12 w-36 bg-theme-blue text-white rounded-2xl text-sm shadow-button_shadow">
              <MotorInsuranceModal />
            </button>
          </div>
        </div>
      </section>
      {/* Non Motor */}
      <section className="relative bg-[#f8f9fa5c] mt-[39px]">
        <div className="flex flex-col items-center ">
          <h1 className="text-4xl text-[#1F2347] font-bold mt-20 text-center">
            Non Motor
          </h1>
          <div className="flex flex-col items-center h-1 w-48 bg-[#1B8DA6] mt-4"></div>
        </div>
        <div className="flex flex-col items-center mt-6">
          <h5 className="text-xl text-[#1F2347] font-semibold text-center">
            Our Non-Motor Product
          </h5>
        </div>
        <div className="flex flex-wrap  justify-around px-12 pb-16">
          <div className="flex relative mt-24 flex-col w-[289px] min-h-[22.5rem] pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" object-cover" src={NMCard1} alt="" />
            <div
              className=" w-18 h-18 flex justify-center items-center absolute top-44 right-6 bg-white rounded-md"
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            >
              <img className=" h-10" src={NMCard1_2} alt="" />
            </div>
            <div className="flex flex-col items-center h-[0.35rem] w-18 ml-5 bg-[#1B8DA6] mt-4"></div>
            <h2 className="text-[#3f3d56e3] font-bold text-2xl ml-5 mt-10">
              Fire and Burglary
            </h2>
            <p className="p-7 text-black/70 font-medium text-sm leading-6">
              Legal contract between an insurance company and the policyholder
              which guarantees that any loss or damages caused to the
              policyholder's property in a fire.
            </p>
          </div>
          <div className="flex relative mt-24 flex-col w-[289px] min-h-[22.5rem] pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" object-cover" src={NMCard2} alt="" />
            <div
              className=" w-18 h-18 flex justify-center items-center absolute top-44 right-6 bg-white rounded-md"
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            >
              <img className=" h-10" src={NMCard2_2} alt="" />
            </div>
            <div className="flex flex-col items-center h-[0.35rem] w-18 ml-5 bg-[#1B8DA6] mt-4"></div>
            <h2 className="text-[#3f3d56e3] font-bold text-2xl ml-5 mt-10">
              Marine Insurance
            </h2>
            <p className="p-7 text-black/70 font-medium text-sm leading-6">
              It covers against any damage/loss caused to cargo vessels, ships,
              etc. in which the goods are transported from one point of origin
              to another.
            </p>
          </div>
          <div className="flex relative mt-24 flex-col w-[289px] min-h-[22.5rem] pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" object-cover" src={NMCard3} alt="" />
            <div
              className=" w-18 h-18 flex justify-center items-center absolute top-44 right-6 bg-white rounded-md"
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            >
              <img className=" h-10" src={NMCard3_2} alt="" />
            </div>
            <div className="flex flex-col items-center h-[0.35rem] w-18 ml-5 bg-[#1B8DA6] mt-4"></div>
            <h2 className="text-[#3f3d56e3] font-bold text-2xl ml-5 mt-10">
              Contractor All Risk
            </h2>
            <p className="p-7 text-black/70 font-medium text-sm leading-6">
              It covers comprehensive coverage against all types of physical
              loss or damage to your building works, public liability,
              installation and constructional machinery that could impact your
              business.
            </p>
          </div>
          <div className="flex relative mt-24 flex-col w-[289px] min-h-[22.5rem] pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" object-cover" src={NMCard4} alt="" />
            <div
              className=" w-18 h-18 flex justify-center items-center absolute top-44 right-6 bg-white rounded-md"
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            >
              <img className=" h-10" src={NMCard4_2} alt="" />
            </div>
            <div className="flex flex-col items-center h-[0.35rem] w-18 ml-5 bg-[#1B8DA6] mt-4"></div>
            <h2 className="text-[#3f3d56e3] font-bold text-2xl ml-5 mt-10">
              Plant and Machinery
            </h2>
            <p className="p-7 text-black/70 font-medium text-sm leading-6">
              It covers comprehensive coverage against all types of physical
              loss or damage to your plant and machinery that could impact your
              business.
            </p>
          </div>
          <div className="flex relative mt-24 flex-col w-[289px] min-h-[22.5rem] pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" object-cover" src={NMCard5} alt="" />
            <div
              className=" w-18 h-18 flex justify-center items-center absolute top-44 right-6 bg-white rounded-md"
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            >
              <img className=" h-10" src={NMCard5_2} alt="" />
            </div>
            <div className="flex flex-col items-center h-[0.35rem] w-18 ml-5 bg-[#1B8DA6] mt-4"></div>
            <h2 className="text-[#3f3d56e3] font-bold text-2xl ml-5 mt-10">
              Workman Compensation
            </h2>
            <p className="p-7 text-black/70 font-medium text-sm leading-6">
              It covers the legal liability of an employer to provide
              compensation to its workmen in case of their death or accident.
            </p>
          </div>
          <div className="flex relative mt-24 flex-col w-[289px] min-h-[22.5rem] pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" object-cover" src={NMCard6} alt="" />
            <div
              className=" w-18 h-18 flex justify-center items-center absolute top-44 right-6 bg-white rounded-md"
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            >
              <img className=" h-10" src={NMCard6_2} alt="" />
            </div>
            <div className="flex flex-col items-center h-[0.35rem] w-18 ml-5 bg-[#1B8DA6] mt-4"></div>
            <h2 className="text-[#3f3d56e3] font-bold text-2xl ml-5 mt-10">
              Erection All Risk
            </h2>
            <p className="p-7 text-black/70 font-medium text-sm leading-6">
              It provides protection to Contractors, Manufacturers, and
              Suppliers from loss arising out of the erection and installation
              of machinery, plants, etc.
            </p>
          </div>
          <div className="flex relative mt-24 flex-col w-[289px] min-h-[22.5rem] pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" object-cover" src={NMCard7} alt="" />
            <div
              className=" w-18 h-18 flex justify-center items-center absolute top-44 right-6 bg-white rounded-md"
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            >
              <img className=" h-10" src={NMCard7_2} alt="" />
            </div>
            <div className="flex flex-col items-center h-[0.35rem] w-18 ml-5 bg-[#1B8DA6] mt-4"></div>
            <h2 className="text-[#3f3d56e3] font-bold text-2xl ml-5 mt-10">
              Cyber Insurance
            </h2>
            <p className="p-7 text-black/70 font-medium text-sm leading-6">
              It offers compensation to organisations for expenses, fees, etc
              arising due to cyber breaches. It also includes the loss or theft
              of the client/employee information.
            </p>
          </div>
          <div className="flex relative mt-24 flex-col w-[289px] min-h-[22.5rem] pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" object-cover" src={NMCard8} alt="" />
            <div
              className=" w-18 h-18 flex justify-center items-center absolute top-44 right-6 bg-white rounded-md"
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            >
              <img className=" h-10" src={NMCard8_2} alt="" />
            </div>
            <div className="flex flex-col items-center h-[0.35rem] w-18 ml-5 bg-[#1B8DA6] mt-4"></div>
            <h2 className="text-[#3f3d56e3] font-bold text-2xl ml-5 mt-10">
              Travel Insurance
            </h2>
            <p className="p-7 text-black/70 font-medium text-sm leading-6">
              It covers unexpected losses while you're travelling
              internationally or domestically, flasic plans mostly cover
              overSRAS TILÓÍCH expenses, however, comprehensive plans also offer
              coverage for light delays,etc.
            </p>
          </div>
          <div className="flex relative mt-24 flex-col w-[289px] min-h-[22.5rem] pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" object-cover" src={NMCard9} alt="" />
            <div
              className=" w-18 h-18 flex justify-center items-center absolute top-44 right-6 bg-white rounded-md"
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            >
              <img className=" h-10" src={NMCard9_2} alt="" />
            </div>
            <div className="flex flex-col items-center h-[0.35rem] w-18 ml-5 bg-[#1B8DA6] mt-4"></div>
            <h2 className="text-[#3f3d56e3] font-bold text-2xl ml-5 mt-10">
              Commercial Liability
            </h2>
            <p className="p-7 text-black/70 font-medium text-sm leading-6">
              It covers all the liabilities on behalf of the policyholder. The
              insurer shall pay off the damages related to bodily
              injuries/accidental death or third-party illnesses.
            </p>
          </div>
          <div className="flex relative mt-24 flex-col w-[289px] min-h-[22.5rem] pb-6 bg-white shadow-card_shadow hover:shadow-tile_hover_shadow rounded-lg">
            <img className=" object-cover" src={NMCard10} alt="" />
            <div
              className=" w-18 h-18 flex justify-center items-center absolute top-44 right-6 bg-white rounded-md"
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            >
              <img className=" h-10" src={NMCard10_2} alt="" />
            </div>
            <div className="flex flex-col items-center h-[0.35rem] w-18 ml-5 bg-[#1B8DA6] mt-4"></div>
            <h2 className="text-[#3f3d56e3] font-bold text-2xl ml-5 mt-10">
              Professional Insurance
            </h2>
            <p className="p-7 text-black/70 font-medium text-sm leading-6">
              It is designed for a target group that includes architects,
              doctors, engineers, etc to protect their business from legal
              claims.
            </p>
          </div>
        </div>
      </section>
      {/* Non Motor Quote */}
      <section className="nonMotor h-[209px] relative  m-10">
        <div className=" bg-[#1B8DA6] rounded-xl h-[inherit]">
          <img
            className="absolute top-[91px] left-[-3px] hidden sm:block"
            src={Polygon1}
            alt=""
          />
          <img
            className="absolute top-[105px] left-[-9px] hidden sm:block"
            src={Polygon2}
            alt=""
          />
          <img
            className="absolute top-[94px] right-[-7px] hidden sm:block"
            src={Polygon3}
            alt=""
          />
          <img
            className="absolute top-[105px] right-[-8px] hidden sm:block"
            src={Polygon4}
            alt=""
          />
          <div className="absolute flex flex-wrap top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-full justify-center">
            <p className=" text-white text-xl mt-3 font-semibold mr-8 text-center">
              Get a Personalized Quote for all the Non-Motor Products{" "}
            </p>
            <button className="flex h-12 w-36 bg-white text-black rounded-2xl text-sm shadow-button_shadow">
              <NonMotorInsuranceModal />
            </button>
          </div>
        </div>
      </section>
      {/* Why Choose Us Section */}

      <section className="flex flex-wrap justify-evenly xs:py-10 sm:py-14 bg-[#EBE9EB]/30 ">
        <div className="flex-col m-10 p-10 xs:mt-0 sm:mt-12">
          <h2 className="font-bold text-black text-3xl">
            <p className=" text-retechgen-blue inline-block">Why Choose Us?</p>
            <div className="flex flex-col items-center h-1 w-[16rem] bg-black mt-3"></div>
          </h2>
          <h2 className="text-black font-medium text-lg mt-6">
            Our dedicated and qualified professionals <br />
            are continuously exploring &innovating to <br />
            avail our elite clients with best in class,
            <br /> reliable and prompt IT consulting, software
            <br /> development, design services, custom
            <br /> products, mobility and cloud operations with
            <br /> end-to-end customization.
          </h2>

          <Link to="/contact">
            <button className="xs:mt-10  hover:bg-retechgen-blue flex h-12 w-36 bg-theme-blue text-white rounded-2xl text-sm shadow-button_shadow">
              <p className=" self-center m-auto font-semibold">Get in Touch</p>
            </button>
          </Link>
        </div>

        <div className="flex relative w-132 flex-wrap xs:mx-[8rem] sm:mx-0">
          <div className="xs:-mt-[2.6rem] sm:mt-0 flex-col flex-wrap">
            <div className="flex flex-wrap w-52 h-52 bg-white rounded-lg shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out m-10 hover:shadow-tile_hover_shadow">
              <div className="flex-col m-auto  ">
                <img className="m-auto mb-5" src={QuickestGrowth} alt="" />
                <h2 className="mb-4 text-center text-theme-blue-text font-medium text-lg">
                  Quickest Growing Insurance Consultancy
                </h2>
              </div>
            </div>

            <div className="flex flex-wrap w-52 h-52 bg-white rounded-lg shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out m-10 hover:shadow-tile_hover_shadow">
              <div className="flex-col m-auto  ">
                <img className="m-auto mb-5" src={CustomerSupport} alt="" />
                <h2 className="mb-4 text-center text-theme-blue-text font-medium text-lg">
                  24/7 Customer Support
                </h2>
              </div>
            </div>
          </div>

          <div className="flex-col sm:absolute xs:-mt-10 sm:mt-0 sm:bottom-12  sm:left-70 xs:flex-wrap">
            <div className="flex w-52 h-52 bg-white rounded-lg shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out m-10 hover:shadow-tile_hover_shadow">
              <div className="flex-col m-auto  ">
                <img className="m-auto mb-5" src={Team} alt="" />
                <h2 className="mb-4 text-center text-theme-blue-text font-medium text-lg">
                  Dedicated Team for Claim Settlement
                </h2>
              </div>
            </div>

            <div className="flex w-52 h-52 bg-white rounded-lg shadow-card_shadow2 hover:border-2 hover:border-solid hover:border-retechgen-blue/70 hover:transition hover:duration-300 hover:ease-in-out m-10 hover:shadow-tile_hover_shadow">
              <div className="flex-col m-auto  ">
                <img className="m-auto mb-5" src={StrongIndustry} alt="" />
                <h2 className="mb-4 text-center text-theme-blue-text font-medium text-lg">
                  Strong Industry Relation
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* OUR PARTNERS Section */}

      <section>
        <div className="flex flex-col items-center ">
          <h1 className="text-4xl text-[#1F2347] font-bold mt-20 text-center">
            OUR - PARTNERS
          </h1>
          <div className="flex flex-col items-center h-1 w-60 bg-[#1B8DA6] mt-4"></div>
        </div>
        <div className="flex flex-col items-center mt-6">
          <h5 className="text-xl text-[#1F2347] font-semibold text-center">
            Our partner companies
          </h5>
        </div>
        <br />
        <br />
        <br />
        <br />
        <Carousel />

      </section>

      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default QisProductPage;
